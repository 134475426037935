import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tech-chat-title',
  templateUrl: './tech-chat-title.component.html',
  styleUrls: ['./tech-chat-title.component.scss'],
})
export class TechChatTitleComponent {
  @Input() disabled = false;
  @Input() title = '';
  @Input() myTso = false;
  @Input() iconName = '';
  @Input() buttonText = '';
  @Input() totalUnreadCounter: number | string;

  @Output() toggled = new EventEmitter();
  @Output() edit = new EventEmitter();

  isExpanded = false;

  onToggleExpanded() {
    this.isExpanded = !this.isExpanded;
    this.toggled.emit();
  }

  openEditor() {
    this.edit.emit();
  }
}
