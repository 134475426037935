<div class="groups-container">
  <div class="group">
    <div class="d-flex align-items-center group-left">
      <button
        class="icon-button chat-expansion__caret"
        [class.disabled]="!groups.length"
        (click)="onToggleGroupsExpanded()"
      >
        <app-svg-icon [name]="isGroupsExpanded ? 'caret_up' : 'caret_down'" width="12" height="12"></app-svg-icon>
      </button>
      <span class="group-title">Группы и темы</span>
      <div
        *ngIf="
          chatSectionSelected.name === chatSectionsEnum.ADMIN ||
          chatSectionSelected.name === chatSectionsEnum.HOLDING ||
          chatSectionSelected.name === chatSectionsEnum.TRADE
        "
        class="group__filters ml-2"
        [class.invisible]="chatSectionSelected.name === chatSectionsEnum.TRADE"
      >
        <div class="sort__button">
          <button class="icon-button">
            <app-svg-icon [name]="'sort-amount-down'" width="12" height="12"></app-svg-icon>
          </button>
        </div>
        <div (click)="onSortDirectionNext()" class="sort__button">
          <ul *ngIf="isChoosingSortDirection" class="sort__direction-select list-group">
            <li
              class="list-group-item"
              *ngFor="let type of orderTypeDirections"
              (click)="onSortDirectionChange($event, type)"
            >
              <span [class.c-black]="type.selected">{{ type.label }}</span>
              <ul class="list-group" *ngIf="type.selected && type.directions?.length">
                <li
                  *ngFor="let direction of type.directions"
                  [class.c-black]="direction.selected"
                  (click)="onSortDirectionChange($event, direction)"
                >
                  {{ direction.label }}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center group-right" [style.paddingLeft]="scrollPosition + 'px'">
      <span class="icon-link">
        <button class="icon-button">
          <app-svg-icon [name]="'question-circle-color'" width="16" height="16"></app-svg-icon>
        </button>
      </span>
      <button
        class="icon-link icon-button add-group"
        (click)="openGroupNameModal()"
        *ngIf="
          (chatSectionSelected.name !== chatSectionsEnum.TRADE &&
            chatSectionSelected.name !== chatSectionsEnum.DUTY_TSO &&
            user?.type !== rolesEnum.PARTNER &&
            user?.type !== rolesEnum.ACCOUNTANT &&
            user?.type !== rolesEnum.EXPERT) ||
          (chatSectionSelected.name === chatSectionsEnum.TECH &&
            user?.type === rolesEnum.PARTNER &&
            techType &&
            techType !== techTypeEnum.MY_TECH)
        "
      >
        <app-svg-icon [name]="'plus'" width="12" height="12"></app-svg-icon>
      </button>

      <!-- temp-save  -->
      <!-- <div class="group-menu-trigger">
        <a
          class="icon-link"
          (click)="openDutyFilterMenu()"
          *ngIf="chatSectionSelected.name === chatSectionsEnum.DUTY_TSO"
        >
          <app-svg-icon [name]="'sort-amount-down'" width="12" height="12" class="icon-button"></app-svg-icon>
        </a>
        <div class="group-menu" *ngIf="isDutyFilterMenuOpened">
          <div>
            <a class="icon-link" (click)="openDutyTsoFilter($event)">
              <app-svg-icon [name]="'sort-amount-down'" width="12" height="12" class="icon-button"></app-svg-icon>
            </a>
          </div>
          <div>
            <a class="icon-link" (click)="resetDutyTsoFilter()">
              <app-svg-icon [name]="'ban'" width="12" height="12" class="icon-button"></app-svg-icon>
            </a>
          </div>
        </div>
      </div> -->
      <span
        class="chat-number"
        [style.visibility]="totalUnreadCounter ? 'visible' : 'hidden'"
        [class.chat-number__10]="totalUnreadCounter === '10+'"
        >{{ totalUnreadCounter }}</span
      >
    </div>
  </div>

  <div *ngIf="isGroupsExpanded" class="groups-list">
    <div *ngFor="let group of groups | groupSort : order | groupsRolesFilter : rolesSelected">
      <app-chat-group [group]="group" [contactSelected]="contactSelected"></app-chat-group>
    </div>
  </div>
</div>

<!-- <div *ngIf="chatSectionSelected.name === chatSectionsEnum.DUTY_TSO">
  <app-chat-roles-filter
    [isDutyFilterModalOpened]="isDutyFilterModalOpened"
    [selected]="rolesSelected"
    (closeEvent)="closeDutyTsoFilter()"
    (submitEvent)="applyDutyTsoFilter($event)"
  ></app-chat-roles-filter>
</div> -->

<app-modal-window [isShowed]="isShowNameModal" (closeEvent)="isShowNameModal = false" centered="true">
  <app-chat-group-theme-name-form
    *ngIf="isShowNameModal"
    [isShowed]="isShowNameModal"
    title="Создать группу"
    (cancelEdit)="closeModalName()"
    (saveName)="createGroup($event)"
  ></app-chat-group-theme-name-form>
</app-modal-window>
