import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toLowerCaseUserRole',
})
export class ToLowerCaseUserRolePipe implements PipeTransform {
  transform(userRole: string): string {
    if (userRole.length > 3) {
      return userRole.toLowerCase();
    }
    return userRole;
  }
}
