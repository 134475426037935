import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import { ContactsFilterPipe } from '@app/shared/pipes/contacts-filter.pipe';

import { ChatComponent } from './chat.component';
import { ChatTabComponent } from './chat-tabs/chat-tab/chat-tab.component';
import { ChatTabsComponent } from './chat-tabs/chat-tabs.component';
import { ChatSectionSelectComponent } from './chat-section-select/chat-section-select.component';
import { ChatMessagesComponent } from './chat-messages/chat-messages.component';
import { ChatInputComponent } from './chat-input/chat-input.component';
import { ChatGroupsComponent } from './chat-groups/chat-groups.component';
import { ChatContactsComponent } from './chat-contacts/chat-contacts.component';
import { ChatContactComponent } from './chat-contacts/chat-contact/chat-contact.component';
import { ChatContactsFilterComponent } from './chat-contacts-filter/chat-contacts-filter.component';
import { ChatGroupOrThemeEditComponent } from './chat-groups/chat-group-or-theme-edit/chat-group-or-theme-edit.component';
import { ChatGroupComponent } from './chat-groups/group/group.component';
import { ChatThemeComponent } from './chat-groups/group/chat-theme/chat-theme.component';
import { ChangeTsoComponent } from './change-tso/change-tso.component';
import { ChatContactsOnlyFilterComponent } from './chat-contacts-only-filter/chat-contacts-only-filter.component';
import { SharedModule } from '@app/shared/shared.module';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { GroupSortPipe } from './pipes/group-sort.pipe';
import { RouterModule } from '@angular/router';
import { ChatRolesFilterComponent } from './chat-roles-filter/chat-roles-filter.component';
import { GroupsRolesFilterPipe } from './pipes/groups-roles-filter.pipe';
import { AccessUsersSortPipe } from './pipes/access-users-sort.pipe';
import { FileManagerPublicModule } from '@app/file_manager/file-manager-public.module';
import { SVGSpriteModule } from '@app/svg-sprite/svg-sprite.module';
import { ChatGroupThemeNameFormComponent } from './chat-groups/chat-group-theme-name-form/chat-group-theme-name-form.component';
import { DestroyService } from '@app/services/destroy.service';
import { TreeModule } from '@circlon/angular-tree-component';
import { ChatUserItemComponent } from './chat-groups/chat-group-or-theme-edit/chat-user-item/chat-user-item.component';
import { ToLowerCaseUserRolePipe } from './pipes/to-lower-case-user-role.pipe';
import { TechChatComponent } from './tech-chat/tech-chat.component';
import { TechChatTitleComponent } from './tech-chat/tech-chat-title/tech-chat-title.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    SharedModule,
    NgbModule,
    NgbPopoverModule,
    FileManagerPublicModule,
    RouterModule,
    SVGSpriteModule,
    TreeModule,
  ],
  declarations: [
    ChatComponent,
    ChatContactComponent,
    ChatContactsComponent,
    ChatGroupsComponent,
    ChatInputComponent,
    ChatMessagesComponent,
    ChatSectionSelectComponent,
    ChatTabsComponent,
    ChatTabComponent,
    ChatContactsFilterComponent,
    ChatGroupComponent,
    ChatGroupOrThemeEditComponent,
    ChatThemeComponent,
    ChangeTsoComponent,
    ContactsFilterPipe,
    ChatContactsOnlyFilterComponent,
    GroupSortPipe,
    ChatRolesFilterComponent,
    GroupsRolesFilterPipe,
    AccessUsersSortPipe,
    ChatGroupThemeNameFormComponent,
    ChatUserItemComponent,
    ToLowerCaseUserRolePipe,
    TechChatComponent,
    TechChatTitleComponent,
  ],
  exports: [ChatComponent, AccessUsersSortPipe],
  providers: [DestroyService],
})
export class ChatModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far);
  }
}
