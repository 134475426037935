<div #scrollMe [scrollTop]="scrollHeight" class="scroll-me">
  <ng-container *ngIf="!isTradeGroup(roomSelected)">
    <ng-container *ngIf="messages.length; else noMessages">
      <div
        *ngFor="let message of messages; trackBy: trackById"
        class="chat-messages-row"
        [attr.data-id]="message.id"
        #messageElement
      >
        <div
          class="chat__message"
          [ngClass]="{
            my: message.author_id === userId,
            response: message.author_id !== userId,
            unread: message.id > roomSelected.last_read_message_id && message.author_id !== userId
          }"
        >
          <div class="chat__message-info">
            <span *ngIf="message.author_id === userId" class="chat__message-info_bold">Я</span>
            <span *ngIf="message.author_id !== userId" class="chat__message-info_bold">
              {{ message.author | initials }}, {{ message.author.type | roleName }}
            </span>
            <span class="chat__message-info_light-text">
              {{ message.created_at | dateTimeFormat : timezoneOffset }}
            </span>
          </div>
          <div class="chat__message-content">
            <div
              #messageContent
              class="content-text"
              [ngClass]="{ expanded: isExpanded(message) }"
              [innerHTML]="message.content | linkify"
            ></div>
            <button
              *ngIf="isExpanded(message) || isOverflowing(messageContent)"
              (click)="toggleExpand(message)"
              class="chat__message-action content-button"
            >
              {{ isExpanded(message) ? 'Скрыть' : 'Читать далее' }}
            </button>

            <div class="chat__message-hover-menu">
              <button (click)="onReplyMessage(message)" class="chat__message-action">Ответить</button>
              <button (click)="onForwardMessage(message)" class="chat__message-action">Переслать</button>
              <button (click)="onRemoveMessage(message)" class="chat__message-action">Удалить</button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #noMessages>
  <div *ngIf="!isMessagesLoading" class="chat-messages-row">Нет сообщений</div>
</ng-template>

<!-- файлы нужно доработать при реализации preview, в данный момент массив attached_files всегда пустой -->
<!--          <div class="mb-2 blue-links" *ngIf="message.attached_files.length">-->
<!--            <div *ngFor="let file of message.attached_files">-->
<!--              <a (click)="downloadFile(file)" class="d-inline-flex mr-3">-->
<!--                <app-svg-icon name="file" width="16" height="16"></app-svg-icon>-->
<!--                {{ file.filename }}-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->

<!-- для чего это если есть scroll, и все сообщения загружаются сразу, на макете/ТЗ не заметил этого -->
<!--<div *ngIf="count > messages.length && !!messages?.length" class="row">-->
<!--  <div class="col-12 text-center">-->
<!--    <a (click)="showMoreMessages()" class="chat__show-messages-btn">Показать раньше</a>-->
<!--  </div>-->
<!--</div>-->
