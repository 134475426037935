import { TradeCardTabConfig } from '../models/trade-card-tabs.model';
import { AccessRights, TradeCardStatuses } from './trades.constants';

export enum TradeCardTabsRoutes {
  EMPLOYEES_OPTIONS = 'employees',
  SEARCH_SUPPLIERS = 'search-suppliers',
  NOTES = 'notes',
  PURCHASE_SYSTEM = 'lots',
  TERM_OF_BIDDING = 'terms-of-bidding',
  INVITE_SUPPLIERS = 'invite-suppliers',
  REPORT = 'report',
  TRADES = 'trades',
  CHECK_CUSTOMER = 'check-customer',
}

export const CUSTOMER_TRADE_CARD_MAIN_TABS: TradeCardTabConfig[] = [
  {
    id: TradeCardTabsRoutes.EMPLOYEES_OPTIONS,
    url: TradeCardTabsRoutes.EMPLOYEES_OPTIONS,
    hasOperations: true,
    icon: {
      name: 'unlock',
      width: 18,
      height: 20,
    },
    invalid: false,
    disabled: false,
    lockId: 'lock_sharing_settings',
    name: 'Права',
    title: 'Сотрудники с доступом к КТ',
    whoCanEdit: [AccessRights.OWNER],
    canEditTradeStatuses: [
      TradeCardStatuses.PROJECT,
      TradeCardStatuses.PRE_TRADE,
      TradeCardStatuses.TEMPLATE,
      TradeCardStatuses.TRADE,
    ],
  },
  {
    id: TradeCardTabsRoutes.SEARCH_SUPPLIERS,
    url: TradeCardTabsRoutes.SEARCH_SUPPLIERS,
    hasOperations: true,
    icon: {
      name: 'handshake',
      width: 34,
      height: 20,
    },
    lockId: 'lock_provider_selection',
    name: 'Поставщики',
    title: 'Выбранные товары, работы и услуги',
    whoCanEdit: [AccessRights.OWNER, AccessRights.EDIT],
    canEditTradeStatuses: [TradeCardStatuses.PROJECT, TradeCardStatuses.TEMPLATE],
  },
  {
    id: TradeCardTabsRoutes.NOTES,
    url: TradeCardTabsRoutes.NOTES,
    hasOperations: true,
    icon: {
      name: 'file-alt',
      width: 21,
      height: 20,
    },
    lockId: 'lock_notes',
    name: 'Документы',
    title: 'Документы к торгам',
    whoCanEdit: [AccessRights.OWNER, AccessRights.EDIT],
    canEditTradeStatuses: [TradeCardStatuses.PROJECT, TradeCardStatuses.PRE_TRADE, TradeCardStatuses.TEMPLATE],
    showHeaderOnEdit: true,
  },
  {
    id: TradeCardTabsRoutes.PURCHASE_SYSTEM,
    url: TradeCardTabsRoutes.PURCHASE_SYSTEM,
    hasOperations: true,
    icon: {
      name: 'shopping-basket',
      width: 26,
      height: 20,
    },
    lockId: 'lock_list_lots',
    name: 'Закупки',
    title: 'Список закупок и приоритеты заказчика',
    whoCanEdit: [AccessRights.OWNER, AccessRights.EDIT],
    canEditTradeStatuses: [TradeCardStatuses.PROJECT, TradeCardStatuses.PRE_TRADE, TradeCardStatuses.TEMPLATE],
    showHeaderOnEdit: true,
  },
  {
    id: TradeCardTabsRoutes.TERM_OF_BIDDING,
    url: TradeCardTabsRoutes.TERM_OF_BIDDING,
    hasOperations: true,
    icon: {
      name: 'clock',
      width: 20,
      height: 20,
    },
    lockId: 'lock_date_setting',
    name: 'Сроки',
    title: 'Сроки',
    whoCanEdit: [AccessRights.OWNER, AccessRights.EDIT],
    canEditTradeStatuses: [TradeCardStatuses.PROJECT, TradeCardStatuses.PRE_TRADE, TradeCardStatuses.TEMPLATE],
    showHeaderOnEdit: true,
  },
  {
    id: TradeCardTabsRoutes.INVITE_SUPPLIERS,
    url: TradeCardTabsRoutes.INVITE_SUPPLIERS,
    hasOperations: true,
    icon: {
      name: 'envelope',
      width: 20,
      height: 20,
    },
    lockId: 'lock_invite_tab',
    name: 'Приглашения',
    title: 'Приглашения',
    whoCanEdit: [AccessRights.OWNER],
    canEditTradeStatuses: [TradeCardStatuses.PROJECT],
    showHeaderOnEdit: true,
  },
  {
    id: TradeCardTabsRoutes.TRADES,
    url: TradeCardTabsRoutes.TRADES,
    hasOperations: false,
    icon: {
      name: 'gavel',
      width: 20,
      height: 20,
    },
    name: 'Торги',
    title: 'Торги',
    whoCanEdit: [],
    canEditTradeStatuses: [],
  },
  {
    id: TradeCardTabsRoutes.REPORT,
    url: TradeCardTabsRoutes.REPORT,
    hasOperations: false,
    icon: {
      name: 'portable-document-format',
      width: 20,
      height: 20,
    },
    name: 'Отчёт',
    title: 'Отчет по торгам',
    whoCanEdit: [],
    canEditTradeStatuses: [],
  },
];

export const SUPPLIER_TRADE_CARD_MAIN_TABS: TradeCardTabConfig[] = [
  {
    id: TradeCardTabsRoutes.EMPLOYEES_OPTIONS,
    url: TradeCardTabsRoutes.EMPLOYEES_OPTIONS,
    hasOperations: true,
    icon: {
      name: 'unlock',
      width: 18,
      height: 20,
    },
    invalid: false,
    disabled: false,
    lockId: 'lock_provider_access',
    name: 'Права',
    title: 'Сотрудники с доступом к КТ',
    showHeaderOnEdit: true,
  },
  {
    id: TradeCardTabsRoutes.NOTES,
    url: TradeCardTabsRoutes.NOTES,
    hasOperations: false,
    icon: {
      name: 'file-alt',
      width: 18,
      height: 20,
    },
    name: 'Документы',
    title: 'Документы к торгам',
  },
  {
    id: TradeCardTabsRoutes.PURCHASE_SYSTEM,
    url: TradeCardTabsRoutes.PURCHASE_SYSTEM,
    hasOperations: true,
    icon: {
      name: 'list-ol',
      width: 18,
      height: 20,
    },
    lockId: 'lock_lots',
    name: 'Закупки',
    title: 'Закупки и расчёт позиции',
    showHeaderOnEdit: true,
    hideControlsOnCard: true,
  },
  {
    id: TradeCardTabsRoutes.TRADES,
    url: TradeCardTabsRoutes.TRADES,
    hasOperations: false,
    icon: {
      name: 'gavel',
      width: 20,
      height: 20,
    },
    disabled: true,
    title: 'Торги',
    name: 'Торги',
    showHeaderOnEdit: true,
  },
  {
    id: TradeCardTabsRoutes.REPORT,
    url: TradeCardTabsRoutes.REPORT,
    showHeaderOnEdit: true,
    hasOperations: false,
    icon: {
      name: 'portable-document-format',
      width: 20,
      height: 20,
    },
    name: 'Отчёт',
    title: 'Отчет по торгам',
  },
];
