import { Column, PaymentRegistryRequestConfig } from '../models/payment-registry-column.model';

export enum PaymentWalletColumnsKeys {
  NAME = 'name',
  STATUS = 'status',
  USER_ID = 'user_id',
  TRADE_IDS = 'trade_ids',
  BALANCE_START = 'balance_start',
  CHARGE = 'charge',
  PAYMENT = 'payment',
  BALANCE_END = 'balance_end',
  OPERATION_ID = 'operation_id',
}

/** настройка колонок кошелька */
export const paymentWalletColumns: Column<PaymentRegistryRequestConfig>[] = [
  {
    id: PaymentWalletColumnsKeys.NAME,
    label: 'Пользователь/ Дата операции',
    width: 190,
    filter: 'filter',
    isEnabledSearch: true,
    filterKeys: ['from_date', 'to_date', 'name'],
    data: {
      isSticky: true,
      indent: 0,
    },
  },
  {
    id: PaymentWalletColumnsKeys.STATUS,
    label: 'Статус',
    width: 110,
    filter: 'filter',
    isEnabledFilter: true,
    filterKeys: ['status'],
    data: {
      isSticky: true,
      indent: 190,
    },
  },
  {
    id: PaymentWalletColumnsKeys.USER_ID,
    label: 'ID пользователя',
    width: 180,
    filter: 'search',
    isEnabledSearch: true,
    filterKeys: ['user_id'],
  },
  {
    id: PaymentWalletColumnsKeys.TRADE_IDS,
    label: 'ID торгов',
    width: 130,
    filter: 'search',
    isEnabledSearch: true,
    filterKeys: ['trade_id'],
  },
  {
    id: PaymentWalletColumnsKeys.BALANCE_START,
    label: 'Баланс начала периода (₽)',
    width: 160,
    filter: 'filter',
    isEnabledFilter: true,
    filterKeys: ['from_balance_start', 'to_balance_start'],
  },
  {
    id: PaymentWalletColumnsKeys.CHARGE,
    label: 'Начисления (₽)',
    width: 180,
    filter: 'filter',
    isEnabledFilter: true,
    filterKeys: ['from_charge', 'to_charge'],
  },
  {
    id: PaymentWalletColumnsKeys.PAYMENT,
    label: 'Платежи (₽)',
    width: 160,
    filter: 'filter',
    isEnabledFilter: true,
    filterKeys: ['from_payment', 'to_payment'],
  },
  {
    id: PaymentWalletColumnsKeys.BALANCE_END,
    label: 'Баланс конца периода (₽)',
    width: 160,
    filter: 'filter',
    isEnabledFilter: true,
    filterKeys: ['from_balance_end', 'to_balance_end'],
  },
  {
    id: PaymentWalletColumnsKeys.OPERATION_ID,
    label: 'ID операции',
    width: 150,
    filter: 'filter',
    isEnabledSearch: true,
    filterKeys: ['id'],
  },
];
