<div class="dc-modal">
  <div
    class="modal-content rich-modal"
    id="rich-modal"
    [style.top.px]="y"
    [style.left.px]="x"
    [style.width.px]="width"
    [style.height.px]="height"
    (mousedown)="onWindowPress($event)"
    (mousemove)="onWindowDrag($event)"
  >
    <div (click)="onClose()" class="move-pin__close-button"></div>

    <ng-content></ng-content>
    <div
      style="cursor: nw-resize"
      (mousedown)="onCornerClick($event, topLeftResize)"
      id="rich-modal-top-left-resize"
      class="rich-modal-corner-resize"
    ></div>
    <div
      style="cursor: ne-resize"
      (mousedown)="onCornerClick($event, topRightResize)"
      id="rich-modal-top-right-resize"
      class="rich-modal-corner-resize"
    ></div>
    <div
      style="cursor: sw-resize"
      (mousedown)="onCornerClick($event, bottomLeftResize)"
      id="rich-modal-bottom-left-resize"
      class="rich-modal-corner-resize"
    ></div>
    <div
      style="cursor: se-resize"
      (mousedown)="onCornerClick($event, bottomRightResize)"
      id="rich-modal-bottom-right-resize"
      class="rich-modal-corner-resize"
    ></div>
  </div>
</div>
