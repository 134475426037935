import { Pipe, PipeTransform } from '@angular/core';
import { MultipleSearchSuggestion } from '@app/shared/models/multiple-search.model';
import { FlaskQueryFilter } from '@app/shared/models/filters.model';
import { MultiSelectListItem } from '@app/shared/models/multi-select-list-item';

@Pipe({
  name: 'flaskApiMapper',
})
export class FlaskApiMapperPipe implements PipeTransform {
  transform(
    items: MultipleSearchSuggestion[],
    value: FlaskQueryFilter
  ): (MultipleSearchSuggestion | MultiSelectListItem)[] {
    if (!value?.val.length) {
      return items;
    }

    return items.map((suggestion) => ({
      ...suggestion,
      value:
        !!value && Array.isArray(value)
          ? value.includes(suggestion.id)
          : 'val' in value
          ? value.val.includes(suggestion.data[1]) || value.val.includes(suggestion.id)
          : true,
    }));
  }
}
