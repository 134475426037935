import { ProvidersCountPayload } from '../models/suppliers-selection.model';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { merge, Observable, Subject, throwError as observableThrowError } from 'rxjs';
import { sortTreeObject } from '@app/+competence-map/helpers/competence-map.helpers';
import { FlaskQueryFilter } from '@app/shared/models/filters.model';
import {
  UnitFromFileResponse,
  UnitsMeasure,
  UnitsMeasureParams,
  UpdateUnitsMeasure,
} from '@app/+competence-map/models/units.model';
import { Project, ProjectHistory, ProjectLog, ProjectLogParams } from '@app/+competence-map/models/projects.models';
import { CMCatalogTypes } from '@app/+competence-map/constants/sections.constants';
import {
  FilterTemplate,
  CreateOrChangeFilterTemplateParams,
  UpdateFilterTemplateParams,
  CopyFilterTemplateParams,
} from '@app/+competence-map/models/filter-templates.model';
import { SplitFilterSectionParams, UnionFilterSectionParams } from '@app/+competence-map/models/sections.model';
import {
  CreateRootFilterParams,
  GetTreeInstanceFilter,
  UpdateUserFilterParams,
  UserFilter,
} from '@app/+competence-map/models/user-filters.model';
import {
  ExpertCompetency,
  ExpertCompetencyPatch,
  ExpertCompetencyPost,
  ProviderCompetency,
  ProviderCompetencyPatch,
  ProviderCompetencyPost,
  TradeCompetency,
  TradeCompetencyPatch,
  TradeCompetencyPost,
  TreeSectionsAndFilters,
} from '@app/+competence-map/models/user-competency.model';
import { AuthService } from '@app/shared/services/auth.service';
import { NotificationsService } from 'angular2-notifications';
import { CMProjectStatuses } from '@app/+competence-map/constants/projects.constants';
import { UnitsColumnKeys } from '../constants/units.constants';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { PotentialProviderInfo } from '@app/+trades/models/suppliers.model';
import { ProjectColumns } from '@app/+competence-map/models/project-columns.models';

const STORAGE_SEARCH_DATA_KEY = 'searchData';

@Injectable({
  providedIn: 'root',
})
export class CompetenceService {
  private updateProjectOnAgreementLength = new Subject<unknown>();
  private updateProjectOnAgreementLengthAction$ = this.updateProjectOnAgreementLength.asObservable();

  private projectLogsLengthSubject = new Subject();
  projectLogsLength$ = this.projectLogsLengthSubject.asObservable();

  get updateProjectOnAgreementLengthSelect$(): Observable<number> {
    return merge(this.updateProjectOnAgreementLengthAction$).pipe(
      startWith(0),
      switchMap((_) => this.getProjects()),
      map((value) => {
        const projects = value.filter((item) => {
          if (this.authService.user_type === RolesEnum.EXPERT && +item.author_id === +this.authService.user_id) {
            return item;
          }
          if (this.authService.user_type === RolesEnum.SUPERUSER) {
            return item;
          }
        });
        return projects.filter((item) => item.status === CMProjectStatuses.ON_AGREEMENT)?.length || 0;
      })
    );
  }

  constructor(private http: HttpClient, private authService: AuthService, private notify: NotificationsService) {
    this.error = this.error.bind(this);
  }

  updateProjectLogsLength() {
    this.projectLogsLengthSubject.next();
  }

  // TODO: перенести в notification/messages service + нужен формат ошибок!!!
  error(err: HttpErrorResponse): void {
    const errors = err.error.errors;
    const title = 'Ошибка!';
    let hasError = false;
    if (errors) {
      Object.keys(errors).forEach((key) => {
        if (Array.isArray(errors[key])) {
          errors[key].forEach((text) => {
            this.notify.error(title, text);
          });
        } else {
          // предположим, что это объект
          this.notify.error(title, errors[key][0]);
        }

        hasError = true;
      });
    }

    if (!hasError || !errors) {
      this.notify.error(title, 'Неизвестная ошибка');
    }
  }

  // Обновляем счетчик при согласовании проекта и направлении проекта на согласование
  projectSuccess(status: CMProjectStatuses): void {
    let successMsg = '';

    switch (status) {
      case CMProjectStatuses.AGREED:
        successMsg = 'Проект согласован';
        this.updateProjectOnAgreement();
        break;
      case CMProjectStatuses.ON_AGREEMENT:
        successMsg = 'Проект отправлен на согласование';
        this.updateProjectOnAgreement();
        break;
      case CMProjectStatuses.DELETED:
        break;
      case CMProjectStatuses.ROLLBACK:
        break;
      default:
        successMsg = 'Изменения в проекте сохранены';
    }

    if (status !== CMProjectStatuses.DELETED && status !== CMProjectStatuses.ROLLBACK) {
      this.notify.success('Успешно!', successMsg);
    }
  }

  updateProjectOnAgreement(): void {
    this.updateProjectOnAgreementLength.next('');
  }

  getProjects(filter: FlaskQueryFilter[] = [], size?: number, number?: number): Observable<Project[]> {
    let paginationParams = {};

    if (size && (number || number === 0)) {
      paginationParams = {
        'page[size]': JSON.stringify(size),
        'page[number]': JSON.stringify(number),
      };
    }

    const params = new HttpParams({
      fromObject: {
        filter: JSON.stringify(filter),
        ...paginationParams,
      },
    });
    return this.http
      .get<Project[]>(`${environment.api_url}/competency-map/projects`, {
        headers: {
          Accept: 'application/vnd.api+json',
        },
        params,
      })
      .pipe(
        catchError((e) => {
          return observableThrowError(e);
        })
      );
  }

  getProjectColumns(
    column: string,
    filter: FlaskQueryFilter[] = [],
    size?: number,
    number?: number
  ): Observable<ProjectColumns> {
    let paginationParams = {};

    if (size && (number || number === 0)) {
      paginationParams = {
        'page[size]': JSON.stringify(size),
        'page[number]': JSON.stringify(number),
      };
    }

    const params = new HttpParams({
      fromObject: {
        column,
        filter: JSON.stringify(filter),
        ...paginationParams,
      },
    });
    return this.http
      .get<ProjectColumns>(`${environment.api_url}/competency-map/projects-column`, {
        params,
      })
      .pipe(
        catchError((e) => {
          return observableThrowError(e);
        })
      );
  }

  updateProject(project: Project): Observable<Project> {
    return this.http.patch(`${environment.api_url}/competency-map/projects/${project.id}`, project);
  }

  getFiltersForSection(id: number): Observable<any> {
    return this.http.get(`${environment.api_url}/competency_map/section/${id}/filters?include=author&parents=1`).pipe(
      map((data) => {
        const keys = Object.keys(data);
        keys.forEach((key) => {
          data[key] = sortTreeObject(data[key]);
        });
        return data;
      })
    );
  }

  searchSuppliers(tradeCardId: number, query): Observable<any> {
    return this.http.get(
      `${environment.api_url}/competency_map/${tradeCardId}/search?include=company${query ? '&' + query : ''}`
    );
  }

  getTree(type: CMCatalogTypes): Observable<any> {
    return this.http
      .get(`${environment.api_url}/competency_map/${type}/tree`)
      .pipe(map((data: any) => sortTreeObject(data)));
  }

  getSectionsAndFilters(filter: FlaskQueryFilter[] = []): Observable<TreeSectionsAndFilters[]> {
    const params = new HttpParams({
      fromObject: {
        filter: JSON.stringify(filter),
        sort: 'section.title',
      },
    });
    return this.http
      .get<TreeSectionsAndFilters[]>(`${environment.api_url}/competency-map/tree-sections-and-filters`, {
        headers: {
          Accept: 'application/vnd.api+json',
        },
        params,
      })
      .pipe(
        map((value) => {
          return value.map((item) => {
            return {
              ...item,
              providerCompetency: {
                value_bool: null,
                value_range_from: null,
                value_range_to: null,
              } as ProviderCompetency,
            };
          });
        })
      );
  }

  changeStatus(id: number, status: string): Observable<any> {
    return this.http.post(`${environment.api_url}/competency_map/project/${id}/status/${status}`, {});
  }

  getStorageSearchData() {
    const searchData = localStorage.getItem(STORAGE_SEARCH_DATA_KEY);
    return searchData ? JSON.parse(searchData) : {};
  }

  getUnits(filter: FlaskQueryFilter[] = []): Observable<UnitsMeasure[]> {
    const params = new HttpParams({
      fromObject: {
        filter: JSON.stringify(filter),
        sort: UnitsColumnKeys.FULL_NAME,
      },
    });

    return this.http
      .get<UnitsMeasure[]>(`${environment.api_url}/competency-map/units`, {
        headers: {
          Accept: 'application/vnd.api+json',
        },
        params,
      })
      .pipe(
        map((units) => {
          return units.map((unit) => {
            return {
              ...unit,
              updated_by_user_id: unit.updated_by_user?.id,
            };
          });
        }),
        catchError((e) => {
          return observableThrowError(e);
        })
      );
  }

  addUnit(body: UnitsMeasureParams[]): Observable<Project> {
    return this.http.post<Project>(`${environment.api_url}/competency-map/units`, body).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  updateUnit(id: number, body: UpdateUnitsMeasure): Observable<Project> {
    return this.http.put<Project>(`${environment.api_url}/competency-map/units/${id}`, body).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  importUnitsFromFile(file_id: number) {
    return this.http
      .post<UnitFromFileResponse>(`${environment.api_url}/competency-map/units/from-file/${file_id}`, {})
      .pipe(
        catchError((e) => {
          return observableThrowError(e);
        })
      );
  }

  getUnitDetail(id: number): Observable<UnitsMeasure> {
    return this.http.get<UnitsMeasure>(`${environment.api_url}/competency-map/units/${id}`).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  getFilterTemplates(filter: any[] = []): Observable<FilterTemplate[]> {
    const params = new HttpParams({
      fromObject: {
        filter: JSON.stringify(filter),
        sort: 'title',
      },
    });
    return this.http
      .get<FilterTemplate[]>(`${environment.api_url}/competency-map/template-filters`, {
        headers: {
          Accept: 'application/vnd.api+json',
        },
        params,
      })
      .pipe(
        catchError((e) => {
          return observableThrowError(e);
        })
      );
  }

  getTemplateStructureFilter(
    filter: Partial<CreateOrChangeFilterTemplateParams>
  ): Observable<CreateOrChangeFilterTemplateParams[]> {
    const fromObject = {};
    Object.keys(filter).forEach((value) => {
      if (value === 'node_id') {
        fromObject[value] = filter[value];
      } else {
        fromObject[value] = JSON.stringify(filter[value]);
      }
    });
    const params = new HttpParams({
      fromObject,
    });

    return this.http
      .get<CreateOrChangeFilterTemplateParams[]>(`${environment.api_url}/competency-map/template-structure-filters`, {
        params,
      })
      .pipe(
        catchError((e) => {
          return observableThrowError(e);
        })
      );
  }

  copyTemplateStructure(body: CopyFilterTemplateParams): Observable<Project> {
    return this.http.post<Project>(`${environment.api_url}/competency-map/template-filters/copy`, body).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  addFilterTemplate(body: CreateOrChangeFilterTemplateParams): Observable<Project> {
    return this.http.post<Project>(`${environment.api_url}/competency-map/template-filters`, body).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  changeFilterTemplate(body: UpdateFilterTemplateParams): Observable<Project> {
    return this.http.patch<Project>(`${environment.api_url}/competency-map/template-filters/${body.id}`, body).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  updateFilterTemplate(body: Partial<CreateOrChangeFilterTemplateParams>): Observable<Project> {
    return this.http.put<Project>(`${environment.api_url}/competency-map/template-filters`, body).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  getProjectHistory(filter: FlaskQueryFilter[] = []) {
    const params = new HttpParams({
      fromObject: {
        filter: JSON.stringify(filter),
      },
    });
    return this.http
      .get<ProjectHistory[]>(`${environment.api_url}/competency-map/projects-history`, {
        headers: {
          Accept: 'application/vnd.api+json',
        },
        params,
      })
      .pipe(
        catchError((e) => {
          return observableThrowError(e);
        })
      );
  }

  updateProjectLog(body: ProjectLogParams) {
    return this.http.post<ProjectLog[]>(`${environment.api_url}/competency-map/project-log`, body).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  getUserFilters(filter: FlaskQueryFilter[] = []): Observable<UserFilter[]> {
    const params = new HttpParams({
      fromObject: {
        filter: JSON.stringify(filter),
        sort: 'title',
      },
    });
    return this.http
      .get<UserFilter[]>(`${environment.api_url}/competency-map/instance-filters`, {
        headers: {
          Accept: 'application/vnd.api+json',
        },
        params,
      })
      .pipe(
        catchError((e) => {
          return observableThrowError(e);
        })
      );
  }

  getUserFilterTree(filterId: string): Observable<UserFilter[]> {
    return this.http
      .get<GetTreeInstanceFilter>(`${environment.api_url}/competency-map/instance-filters/${filterId}`)
      .pipe(
        map((value) => {
          const result: GetTreeInstanceFilter[] = [value];

          return this.transformToUserFilter(result);
        }),
        catchError((e) => {
          return observableThrowError(e);
        })
      );
  }

  transformToUserFilter(items: GetTreeInstanceFilter[]): UserFilter[] {
    return items.map((item) => {
      return {
        ...item.node_info,
        children: this.transformToUserFilter(item.childes),
      };
    });
  }

  addUserFilter(body: CreateRootFilterParams): Observable<Project> {
    return this.http.post<Project>(`${environment.api_url}/competency-map/instance-filters`, body).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  changeUserFilter(body: UpdateUserFilterParams): Observable<Project> {
    return this.http.patch<Project>(`${environment.api_url}/competency-map/instance-filters/${body.id}`, body).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  getProviders(params: ProvidersCountPayload): Observable<PotentialProviderInfo[]> {
    return this.http.post<PotentialProviderInfo[]>(
      `${environment.api_url}/competency-map/provider-competency/count`,
      params
    );
  }

  getProviderCompetency(filter: FlaskQueryFilter[] = []): Observable<ProviderCompetency[]> {
    const params = new HttpParams({
      fromObject: {
        filter: JSON.stringify(filter),
      },
    });
    return this.http
      .get<ProviderCompetency[]>(`${environment.api_url}/competency-map/provider-competency`, {
        headers: {
          Accept: 'application/vnd.api+json',
        },
        params,
      })
      .pipe(
        catchError((e) => {
          return observableThrowError(e);
        })
      );
  }

  addProviderCompetency(body: ProviderCompetencyPost): Observable<ProviderCompetency> {
    return this.http.post<ProviderCompetency>(`${environment.api_url}/competency-map/provider-competency`, body).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  changeProviderCompetency(body: ProviderCompetencyPatch): Observable<any> {
    return this.http.patch<any>(`${environment.api_url}/competency-map/provider-competency`, body).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  getTradeCompetency(filter: FlaskQueryFilter[] = []): Observable<TradeCompetency[]> {
    const params = new HttpParams({
      fromObject: {
        filter: JSON.stringify(filter),
      },
    });
    return this.http
      .get<TradeCompetency[]>(`${environment.api_url}/competency-map/trade-competency`, {
        headers: {
          Accept: 'application/vnd.api+json',
        },
        params,
      })
      .pipe(
        catchError((e) => {
          return observableThrowError(e);
        })
      );
  }

  addTradeCompetency(body: TradeCompetencyPost): Observable<TradeCompetency> {
    return this.http.post<TradeCompetency>(`${environment.api_url}/competency-map/trade-competency`, body).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  changeTradeCompetency(body: TradeCompetencyPatch): Observable<any> {
    return this.http.patch<any>(`${environment.api_url}/competency-map/trade-competency`, body).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  getExpertCompetency(filter: FlaskQueryFilter[] = []): Observable<ExpertCompetency[]> {
    const params = new HttpParams({
      fromObject: {
        filter: JSON.stringify(filter),
      },
    });
    return this.http
      .get<ExpertCompetency[]>(`${environment.api_url}/competency-map/expert-competency`, {
        headers: {
          Accept: 'application/vnd.api+json',
        },
        params,
      })
      .pipe(
        catchError((e) => {
          return observableThrowError(e);
        })
      );
  }

  addExpertCompetency(body: ExpertCompetencyPost): Observable<ExpertCompetency> {
    return this.http.post<ExpertCompetency>(`${environment.api_url}/competency-map/expert-competency`, body).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  changeExpertCompetency(body: ExpertCompetencyPatch): Observable<ExpertCompetency> {
    return this.http.patch<ExpertCompetency>(`${environment.api_url}/competency-map/expert-competency`, body).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  splitFilterSection(body: SplitFilterSectionParams): Observable<Project> {
    return this.http.post<Project>(`${environment.api_url}/competency-map/template-filters/split`, body).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  unionFilterSection(body: UnionFilterSectionParams): Observable<Project> {
    return this.http.post<Project>(`${environment.api_url}/competency-map/template-filters/union`, body).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }
}
