import { TradesRequestConfig } from '@app/+trades/models/trades.model';
import { InviteSupplierStatus, TradeCardStatus } from '@app/+trades/types/trades.types';
import { InviteSupplierStatuses } from '@app/+trades/constants/suppliers.constants';
import { TradeCardStatuses } from '@app/+trades/constants/trades.constants';
import { CUSTOMER_TRADE_COLUMNS, SUPPLIER_TRADE_COLUMNS } from '@app/+trades/constants/trades-table.constants';

import { EmployeeTreeFilter } from '@app/+employees/shared';
import { EmployeeOrderType } from '@app/shared/enums/employee-order-type.enum';
import { PaymentTabEnum } from '@app/+tariffs/constants/payment.constants';
import { TYPE_PLANNER } from '@app/calendar/constants/calendar.constants';
import { FlaskQueryFilter } from './filters.model';
import { TariffsRequestConfig } from '@app/+tariffs/models/tariffs-table.model';

export interface StatusesSorting {
  enabled: boolean;
  statuses: TradeCardStatus[];
}

export interface InviteStatusesSorting {
  enabled: boolean;
  statuses: InviteSupplierStatus[];
}

export interface UserTradeSettings {
  columns?: string[];
  tradeStatusesSorting?: StatusesSorting;
  inviteStatusesSorting?: InviteStatusesSorting;
  sortingByDate?: string;
  filterConfig?: TradesRequestConfig;
}

export interface TradesSettings {
  customer?: UserTradeSettings;
  supplier?: UserTradeSettings;
}

export type EmployeePageLayout = 'table' | 'tree';

export enum EmployeePageLayoutEnum {
  TABLE = 'table',
  TREE = 'tree',
}

export interface EmployeeSettings {
  extra?: EmployeeTreeFilter;
  hidden_columns?: string[];
  order_type?: EmployeeOrderType;
  page_layout?: EmployeePageLayout;
  filters?: FilterColumnsInTable[];
}

export class EmployeeSettings implements EmployeeSettings {
  extra?: EmployeeTreeFilter;
  hidden_columns?: string[];
  order_type?: EmployeeOrderType;
  page_layout?: EmployeePageLayout;
  filters?: FilterColumnsInTable[];

  constructor(data: EmployeeSettings) {
    if (data) {
      this.page_layout = data.page_layout ? data.page_layout : EmployeePageLayoutEnum.TABLE;
      this.hidden_columns = data.hidden_columns || [];
      this.extra = Object.assign({ all: true }, data.extra || {});
      this.order_type = data.order_type || EmployeeOrderType.ALPHA;
      this.filters = data.filters || [];
    } else {
      this.page_layout = EmployeePageLayoutEnum.TABLE;
      this.hidden_columns = [];
      this.extra = {};
      this.order_type = EmployeeOrderType.ALPHA;
      this.filters = [];
    }
  }
}

export interface CalendarSettings {
  filter?: string;
  type?: TYPE_PLANNER;
}

export interface PaymentRegisterSettings {
  active_tab?: PaymentTabEnum;
  payment_register?: TariffUserSetting;
  payment_accrual?: TariffUserSetting;
  transactions?: TariffUserSetting;
  transactions_file_history?: TariffUserSetting;
  wallet_balance_report?: TariffUserSetting;
}

export class PaymentRegisterSettings implements PaymentRegisterSettings {
  active_tab?: PaymentTabEnum;
  payment_register?: TariffUserSetting;
  payment_accrual?: TariffUserSetting;
  transactions?: TariffUserSetting;
  transactions_file_history?: TariffUserSetting;
  wallet_balance_report?: TariffUserSetting;

  constructor(data: PaymentRegisterSettings) {
    if (data) {
      this.active_tab = data.active_tab ? data.active_tab : PaymentTabEnum.REGISTRY;
      this.payment_register = data.payment_register ? data.payment_register : {};
      this.transactions = data.transactions ? data.transactions : {};
      this.transactions_file_history = data.transactions_file_history ? data.transactions_file_history : {};
      this.wallet_balance_report = data.wallet_balance_report ? data.wallet_balance_report : {};
    } else {
      this.active_tab = PaymentTabEnum.REGISTRY;
      this.payment_register = {};
      this.transactions = {};
      this.transactions_file_history = {};
      this.wallet_balance_report = {};
    }
  }
}

interface FilterColumnsInTable {
  name?: string;
  filter?: FlaskQueryFilter;
}

interface CompetenceMapTabSettings {
  filters: FilterColumnsInTable[];
}

export interface CompetenceMapSettings {
  tab_units_measure: CompetenceMapTabSettings;
  tab_lexicons: CompetenceMapTabSettings;
  tab_instance_filters: CompetenceMapTabSettings;
  tab_project: CompetenceMapTabSettings;
  tab_template_filters: CompetenceMapTabSettings;
}

export class CompetenceMapSettings implements CompetenceMapSettings {
  tab_lexicons: CompetenceMapTabSettings;
  tab_units_measure: CompetenceMapTabSettings;
  tab_instance_filters: CompetenceMapTabSettings;
  tab_project: CompetenceMapTabSettings;
  tab_template_filters: CompetenceMapTabSettings;

  constructor(data: CompetenceMapSettings) {
    this.tab_lexicons = data?.tab_lexicons || {
      filters: [],
    };
    this.tab_units_measure = data?.tab_units_measure || {
      filters: [],
    };
    this.tab_instance_filters = data?.tab_instance_filters || {
      filters: [],
    };

    this.tab_project = data?.tab_project || {
      filters: [],
    };

    this.tab_template_filters = data?.tab_template_filters || {
      filters: [],
    };
  }
}

export interface UserSettings {
  start_page?: string;
  trade_settings?: TradesSettings;
  payment_register_settings?: PaymentRegisterSettings;
  tariff_settings?: TariffSettings;
  employee_settings?: EmployeeSettings;
  calendar_settings?: CalendarSettings;
  competency_map?: CompetenceMapSettings;
}

export interface TariffUserSetting {
  filters?: any;
  hidden_columns?: any;
}

export interface TariffSettings {
  tariff_net?: TariffUserSetting;
  tariff_trade?: TariffUserSetting;
}

export class TariffSettings {
  tariff_net?: TariffUserSetting;
  tariff_trade?: TariffUserSetting;

  constructor(data: TariffSettings) {
    if (data) {
      this.tariff_net = new TariffUserSetting(data.tariff_net);
      this.tariff_trade = new TariffUserSetting(data.tariff_trade);
    } else {
      this.tariff_net = new TariffUserSetting({});
      this.tariff_trade = new TariffUserSetting({});
    }
  }
}

export class TariffUserSetting {
  filters?: any;

  constructor(data: TariffUserSetting) {
    if (data) {
      this.filters = data.filters || [];
    } else {
      this.filters = [];
    }
  }
}

export class UserTradeSettings implements UserTradeSettings {
  columns?: string[];
  tradeStatusesSorting?: StatusesSorting;
  inviteStatusesSorting?: InviteStatusesSorting;
  sortingByDate?: string;
  filterConfig?: TradesRequestConfig;

  constructor(data: UserTradeSettings, columns, tradeStatuses, inviteStatuses) {
    this.columns = data.columns || (data.columns && !data.columns.length) ? data.columns : columns;
    this.tradeStatusesSorting =
      data.tradeStatusesSorting ||
      <StatusesSorting>{
        statuses: (data.tradeStatusesSorting && data.tradeStatusesSorting.statuses) || tradeStatuses,
      };
    this.inviteStatusesSorting =
      data.inviteStatusesSorting ||
      <InviteStatusesSorting>{
        statuses: (data.inviteStatusesSorting && data.inviteStatusesSorting.statuses) || inviteStatuses,
      };
    this.sortingByDate = data.sortingByDate || 'project_date';
    this.filterConfig = Object.assign({}, data.filterConfig);
  }
}

export class UserSettings implements UserSettings {
  start_page?: string;
  trade_settings?: TradesSettings;
  payment_register_settings?: PaymentRegisterSettings;
  tariff_settings?: TariffSettings;
  employee_settings?: EmployeeSettings;
  competency_map?: CompetenceMapSettings;

  constructor(data: UserSettings) {
    this.start_page = data.start_page;
    this.trade_settings = data.trade_settings || {
      customer: {},
      supplier: {},
    };
    this.employee_settings = new EmployeeSettings(data.employee_settings);
    this.tariff_settings = new TariffSettings(data.tariff_settings); /// вызыввает 400

    this.payment_register_settings = new PaymentRegisterSettings(data.payment_register_settings);
    this.competency_map = new CompetenceMapSettings(data?.competency_map);

    this.trade_settings.customer = new UserTradeSettings(
      this.trade_settings.customer || {},
      CUSTOMER_TRADE_COLUMNS.map((item) => item.id),
      [
        TradeCardStatuses.PROJECT,
        TradeCardStatuses.PRE_TRADE,
        TradeCardStatuses.TRADE,
        TradeCardStatuses.ARCHIVE,
        TradeCardStatuses.TEMPLATE,
      ],
      []
    );
    this.trade_settings.supplier = new UserTradeSettings(
      this.trade_settings.supplier || {},
      SUPPLIER_TRADE_COLUMNS.map((item) => item.id),
      [TradeCardStatuses.PRE_TRADE, TradeCardStatuses.TRADE, TradeCardStatuses.ARCHIVE],
      [InviteSupplierStatuses.INVITED, InviteSupplierStatuses.ACCEPTED, InviteSupplierStatuses.DECLINED]
    );
  }
}

export interface GroupInit {
  id: number;
  name: string;
  units_measure: string;
}

export interface Unit {
  group: GroupInit;
  id: number;
  international_code: string;
  international_symbol: string;
  name: string;
  full_name?: string;
  number_code: string;
}
