import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNegative',
})
export class IsNegativePipe implements PipeTransform {
  transform(value: number | string): boolean {
    return +value < 0;
  }
}
