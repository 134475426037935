import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';

import { ChatService } from '@app/chat/services/chat.service';
import { SocketDataService } from '@app/services/socket-data.service';
import { NotificationsService } from 'angular2-notifications';

import { UserFile } from '@app/file-manager/models/user-file.model';
import {
  ExportFileManagerType,
  FileManagerExportComponent,
} from '@app/file-manager/file-manager-export/file-manager-export.component';
import { DestroyService } from '@app/services/destroy.service';
import { ChatRoom } from '@app/chat/models/chat.model';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';

@Component({
  selector: 'app-chat-input',
  templateUrl: './chat-input.component.html',
  styleUrls: ['./chat-input.component.scss'],
  providers: [DestroyService],
})
export class ChatInputComponent implements OnInit {
  @ViewChild('messageInput') private textArea: ElementRef;
  contactSelected: ChatRoom;
  isInputFocused: boolean;
  files: UserFile[] = [];
  // todo: quotedMessage - привязать цитируемое сообщение при реализации функции чата "ответить с цитатой"
  protected quotedMessage: string | null = null;
  protected placeholder: string = 'Введите сообщение';

  protected messageForm = new FormGroup({
    message: new FormControl(''),
  });

  constructor(
    private chatDataService: SocketDataService,
    private chatService: ChatService,
    private notify: NotificationsService,
    private readonly modalService: NgbModal,
    private readonly destroy$: DestroyService
  ) {}

  ngOnInit() {
    this.contactSelected = this.chatService.getContactSelected();
    this.chatService.contactSelectedChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe((contactSelected) => (this.contactSelected = contactSelected));
    this.chatService.focusChanged.pipe(takeUntil(this.destroy$)).subscribe((focus) => {
      this.isInputFocused = focus;
      this.textArea.nativeElement.focus();
    });
  }

  onMessageFormSubmit() {
    const roomId = this.contactSelected.room_id;

    if (!this.contactSelected.id || !this.messageForm.controls.message.value?.trim()) return;

    this.chatDataService.sendMessage(roomId, this.messageForm.controls.message.value, this.files);

    this.messageForm.reset();
    this.chatService.focusChanged.next(true);

    this.files = [];
    this.removeQuotedMessage();
    this.resetTextareaHeight();
  }

  openFileManager() {
    const modal: NgbModalRef = this.modalService.open(FileManagerExportComponent, {
      centered: true,
      animation: true,
      windowClass: 'dc-modal export-file-manager-modal modal-window',
      size: 'xl',
    });

    modal.componentInstance.title = 'Выбрать файлы';
    modal.componentInstance.exportType = ExportFileManagerType.CHAT;
    modal.componentInstance.selectFolders = false;

    modal.result.then((result) => {
      if (result && result.length) {
        this.handleSelectedExport(result);
      }
    });
  }

  handleSelectedExport(files: UserFile[]) {
    const exists = this.files.filter((f) => files.some((file) => f.id === file.id));

    if (exists.length) {
      this.notify.error('Ошибка!', `Данные файлы уже добавлены к сообщению: ${exists.map((file) => file.name)}`);
      return;
    }

    files.forEach((file) => {
      this.files.push(file);
    });
  }

  removeFile(file: UserFile) {
    this.files = this.files.filter((f) => f.id !== file.id);
  }

  changeTextareaHeight() {
    this.resetTextareaHeight();
    this.textArea.nativeElement.style.height = this.textArea.nativeElement.scrollHeight + 'px';
  }

  resetTextareaHeight() {
    this.textArea.nativeElement.style.height = 'auto';
  }

  removeQuotedMessage() {
    this.quotedMessage = null;
  }
}
