import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'month',
})
export class MonthPipe implements PipeTransform {
  transform(value: number, realMonthNumber = false): string {
    return realMonthNumber ? moment.monthsShort(value - 1) : moment.monthsShort(value);
  }
}
