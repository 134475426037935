import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// TODO: обновить socket.io-client до v4
const { io } = require('socket.io-client');

import { environment } from '@app/environments/environment';
import { CHAT_SECTIONS, ChatSectionsEnum } from '../chat/constants/chat-sections.constants';

import { AuthService } from '../shared/services/auth.service';
import { ChatService } from '../chat/services/chat.service';
import { RolesEnum } from '../shared/constants/roles.constants';
import { User } from '../shared/models/user.model';
import { UserTypes } from '../shared/types/user.types';

import {
  ChatGroup,
  ChatGroupUpdate,
  ChatMessage,
  ChatThemeTradeUpdate,
  GroupRemove,
  Theme,
  ThemeRemove,
  ThemeTechUpdate,
  ThemeUpdate,
  TradeTheme,
  ChatUserTree,
} from '../chat/models/chat.model';

import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { NotificationService } from '@app/notification/services/notification.service';
import {
  NOTIFICATION_CREATE_ZIP,
  NOTIFICATION_NOT_ENOUGH_FREE_SPACE,
  NOTIFICATION_NOT_FREE_SPACE,
} from '@app/shared/constants/file-manager.constants';
import { UserFile } from '@app/file-manager/models/user-file.model';
import { TradeRating, TradeTabsLocked } from '@app/+trades/models/trades.model';
import type { CounterData } from '@app/chat/models';

@Injectable({
  providedIn: 'root',
})
export class SocketDataService implements OnDestroy {
  private url = environment.api_url;
  private host = new URL(this.url).host;
  private destroy$ = new Subject();

  private options: SocketIOClient.ConnectOpts = {
    path: '/api/v1/im',
    transports: ['websocket', 'polling'],
    query: {
      auth_token: null,
    },
  };
  private socket: SocketIOClient.Socket;
  private user: User;

  interval = null;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private chatService: ChatService,
    private notificationService: NotificationService
  ) {
    this.authService.userStream.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
      this.init();
    });
  }

  init() {
    this.chatService.userId = +this.authService.user_id;
    this.chatService.userType = this.authService.user_type;
    this.options.query['auth_token'] = this.authService.getToken();
    this.socket = io(this.host, this.options);

    this.chatService.chatLoading.next(true);

    this.interval = setInterval(() => {
      if (this.chatService.chatLoaded.getValue()) {
        clearInterval(this.interval);
      } else {
        this.socket.emit('get_all_data', { auth: this.authService.getToken() });
      }
    }, 1000);

    this.socket.on('connect', () => this.connected());
    this.socket.on('update users', (users: User[]) => {
      // console.log('USERS', users);
      this.chatService.storeUsers(users);

      this.chatService.chatLoaded.next(true);
      this.chatService.chatLoading.next(false);
    });
    this.socket.on('update users tree', (usersTree: ChatUserTree) => {
      // console.log('USERS tree', usersTree);
      this.chatService.storeUserTree(usersTree);
    });

    this.socket.on('update companies', (companies) => {
      // console.log('companies', companies);

      this.chatService.storeCompanies(companies);
    });

    for (const key in CHAT_SECTIONS) {
      if (CHAT_SECTIONS.hasOwnProperty(key)) {
        this.socket.on(`update ${CHAT_SECTIONS[key].name} contacts`, (rooms) => {
          if (this.isCanShowContacts(CHAT_SECTIONS[key].name)) {
            // console.log('contacts', CHAT_SECTIONS[key].name, rooms);
            this.chatService.storeRooms(rooms, CHAT_SECTIONS[key]);
          }
        });

        this.socket.on(this.getUpdateGroupEventName(CHAT_SECTIONS[key].name), (groups) => {
          // console.log(CHAT_SECTIONS[key].name, groups);

          this.chatService.updateGroups(groups, CHAT_SECTIONS[key]);
        });

        this.socket.on(`delete ${CHAT_SECTIONS[key].name} groups`, (groups) => {
          // console.log(`REMOVED GROUPS ${CHAT_SECTIONS[key].name}`, groups);
          this.chatService.removeGroup(groups, CHAT_SECTIONS[key]);
        });

        this.socket.on(`update ${CHAT_SECTIONS[key].name} topics`, (themes) => {
          // console.log(`THEMES ${CHAT_SECTIONS[key].name}`, themes);
          this.chatService.updateThemes(themes, CHAT_SECTIONS[key]);
        });

        this.socket.on(`delete ${CHAT_SECTIONS[key].name} topics`, (themes) => {
          // console.log(`REMOVED THEMES ${CHAT_SECTIONS[key].name}`, themes);
          this.chatService.removeTheme(themes, CHAT_SECTIONS[key]);
        });
      }
    }

    this.socket.on('chat message', (message: ChatMessage) => {
      // console.log('chat message', message);
      this.getMessage(message);
    });

    this.socket.on('delete message', (message) => {
      this.chatService.deleteMessage(message);
    });

    this.socket.on('update room counter', (data: CounterData[]) => {
      this.chatService.updateCounters(data);
    });

    this.socket.on('update admin managers', (data) => {
      this.chatService.updateAdminManager(data);
    });

    this.socket.on('delete admin managers', (data) => {
      this.chatService.deleteAdminManager(data);
    });

    this.socket.on('count new notification', (data) => {
      this.notificationService.updateCounters(data);
    });

    this.socket.on('notification', (data) => {
      // console.log('notification', data);

      if (
        data?.notification?.type === NOTIFICATION_CREATE_ZIP ||
        data?.notification?.type === NOTIFICATION_NOT_ENOUGH_FREE_SPACE ||
        data?.notification?.type === NOTIFICATION_NOT_FREE_SPACE
      ) {
        this.notificationService.addFileNotification(data);
      }
      this.notificationService.addNotification(data);
    });

    this.socket.on('trade rating online', (data: TradeRating) => {
      this.notificationService.updateCustomerPlayTrades(data);
    });

    this.socket.on('disconnect', () => this.disconnected());
  }

  trackTradeTabsLocking(): Observable<TradeTabsLocked> {
    return new Observable<TradeTabsLocked>((observer) => {
      this.socket.emit('join_rooms_by_user', { auth_token: this.authService.getToken() });

      this.socket.on('trade tabs locked', (data: TradeTabsLocked) => {
        observer.next(data);
      });

      return () => {
        this.socket.off('trade tabs locked');
      };
    });
  }

  trackSupplierTradeTabsLocking(): Observable<TradeTabsLocked> {
    return new Observable<TradeTabsLocked>((observer) => {
      this.socket.emit('join_rooms_by_user', { auth_token: this.authService.getToken() });

      this.socket.on('provider trade tabs locked', (data: TradeTabsLocked) => {
        observer.next(data);
      });

      return () => {
        this.socket.off('provider trade tabs locked');
      };
    });
  }

  emitEmployeesAccessChanged(): Observable<void> {
    return new Observable<void>((observer) => {
      this.socket.emit('join_rooms_by_user', { auth_token: this.authService.getToken() });

      this.socket.on('system notification', () => {
        observer.next();
      });

      return () => {
        this.socket.off('system notification');
      };
    });
  }

  isCanShowContacts(sectionName: ChatSectionsEnum) {
    return (
      (([RolesEnum.ADMIN_OF_DIRECTION, RolesEnum.ADMIN_OF_USER, RolesEnum.OPERATOR] as UserTypes[]).includes(
        this.user.type
      ) &&
        (sectionName === ChatSectionsEnum.HOLDING || sectionName === ChatSectionsEnum.TRADE)) ||
      (([RolesEnum.SUPERUSER, RolesEnum.ACCOUNTANT, RolesEnum.EXPERT, RolesEnum.PARTNER] as UserTypes[]).includes(
        this.user.type
      ) &&
        sectionName === ChatSectionsEnum.ADMIN)
    );
  }

  getUpdateGroupEventName(sectionName: ChatSectionsEnum): string {
    return `update ${sectionName} groups`;
  }

  // themes
  addTopic(topic: Theme) {
    this.http
      .post(`${this.url}/chat/${topic.section}_topics/${topic.group_id}`, {
        title: topic.title,
        users: topic.users.map((user) => +user),
      })
      .subscribe(() => {
        this.chatService.goToRoot();
      });
  }

  addTechTopic(title: string) {
    this.http
      .post(`${this.url}/chat/tech_topics`, {
        title,
      })
      .subscribe(() => {
        this.chatService.goToRoot();
      });
  }

  addTradeTopic(topic: TradeTheme) {
    this.http
      .post(`${this.url}/chat/trade-topics`, {
        title: topic.title,
        users: topic.users.map((user) => +user),
        trade_id: topic.trade_id,
      })
      .subscribe(() => {
        this.chatService.goToRoot();
      });
  }

  updateTradeTopic(topic: ChatThemeTradeUpdate) {
    this.http
      .patch(`${this.url}/chat/trade-topics/${topic.id}`, {
        title: topic.title,
        users: topic.users.map((user) => +user),
      })
      .subscribe(() => {
        this.chatService.goToRoot();
      });
  }

  removeTradeTopic(theme_id: string | number) {
    this.http.delete(`${this.url}/chat/trade-topics/${theme_id}`).subscribe(() => {
      this.chatService.goToRoot();
    });
  }

  updateTechTopic(topic: ThemeTechUpdate) {
    this.http
      .patch(`${this.url}/chat/tech_topic/${topic.id}`, {
        title: topic.title,
      })
      .subscribe(() => {
        this.chatService.goToRoot();
      });
  }

  updateTopic(topic: ThemeUpdate) {
    this.http
      .patch(`${this.url}/chat/${topic.section}_topic/${topic.id}`, {
        title: topic.title,
        users: topic.users,
      })
      .subscribe(() => {
        this.chatService.goToRoot();
      });
  }

  removeTopic(topic: ThemeRemove) {
    this.http.delete(`${this.url}/chat/${topic.section.name}_topic/${topic.topic_id}`).subscribe(() => {
      delete this.chatService.themes[topic.section.name][topic.room_id];
      this.chatService.updateThemes([], topic.section);
      this.chatService.goToRoot();
    });
  }

  loadPreviousMessages(data: { room_id: string }) {
    this.http
      .get<{
        items: ChatMessage[];
        count: number;
      }>(`${this.url}/chat/room/${data.room_id}/messages?count=20&offset=0`)
      .subscribe((res) => {
        this.chatService.setPreviousMessages(res.items, res.count, data.room_id);
      });
  }

  getMessage(message: ChatMessage) {
    this.chatService.updateMessages(message);
  }

  setMessageRead(room_id: string, ids: number[]) {
    this.http.post(`${this.url}/chat/mark_as_read`, { room_id, messages_ids: ids }).subscribe();
  }

  sendMessage(room_id: string, content: string, files: UserFile[]) {
    const params = {
      room_id,
      content,
      attached_files_ids: files.map((f) => +f.id),
    };

    if (!params.content) {
      delete params.content;
    }

    this.http.post(`${this.url}/chat/messages`, params).pipe().subscribe();
  }

  deleteMessage(message_id) {
    this.http.delete(`${this.url}/chat/message/${message_id}`).subscribe();
  }

  addGroup(group: ChatGroup) {
    this.http
      .post(`${this.url}/chat/${group.section}_groups`, {
        title: group.title,
        users: group.users,
      })
      .subscribe(() => {
        this.chatService.goToRoot();
      });
  }

  changeAdminGroupOwner(group: ChatGroupUpdate) {
    this.http
      .patch(`${this.url}/chat/admin_group/${group.group_id}`, {
        owner_id: group.owner_id,
      })
      .subscribe(() => {
        if (group.owner_id === this.user.id) {
          delete group.owner_id;
          this.updateGroup(group);
        } else {
          this.updateAdminGroupOwner(group);
        }
      });
  }

  updateAdminGroupOwner(group) {
    const updatedGroups = this.chatService.getGroups().map((item) => {
      if (item.id === group.id) {
        item.owner_id = group.owner_id;
      }
      return item;
    });
    this.chatService.updateGroups(updatedGroups, CHAT_SECTIONS.admin);
  }

  updateGroup(group: ChatGroupUpdate) {
    this.http
      .patch(`${this.url}/chat/${group.section}_group/${group.group_id}`, {
        title: group.title,
        users: group.users,
        owner_id: group.owner_id,
      })
      .subscribe(() => {
        this.chatService.goToRoot();
      });
  }

  removeGroup(group: GroupRemove) {
    this.http.delete(`${this.url}/chat/${group.section}_group/${group.group_id}`).subscribe(() => {});
  }

  // groups end
  connect() {
    this.socket.connect();
  }

  disconnect() {
    this.socket.disconnect();
    this.chatService.groups = {};
    this.chatService.themes = {};
    this.chatService.contacts = {};
    this.chatService.rooms = {};
    this.chatService.users = {};
    this.chatService.companies = {};
    this.chatService.messages = {};
    this.chatService.setContactsOnlyFilter({});
  }

  private connected() {
    console.log('Connected to chat');
  }

  private disconnected() {
    console.log('Disconnected from chat');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
