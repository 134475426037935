export enum PaymentTabEnum {
  REGISTRY = 'payment_register',
  WALLET = 'wallet_balance_report',
  ACCRUALS = 'transactions',
}

export enum PaymentToolbarControlEnum {
  SETTING = 'setting',
  UPLOAD_PAYMENTS = 'upload_payments',
  MANUAL_MODE = 'manual_mode',
  REVERSE_PAYMENT = 'reverse_payment',
  DOWNLOADED_FILES = 'downloaded_files',
  PAY = 'pay',
  PRINT = 'print',
  SAVE_AS = 'save_as',
  HELP = 'help',
}

export interface PaymentTab {
  title: string;
  value: PaymentTabEnum;
  icon?: string[];
}

export const PAYMENT_TABS: PaymentTab[] = [
  { title: 'Общий реестр', value: PaymentTabEnum.REGISTRY, icon: ['fas', 'list-ul'] },
  { title: 'Отчет по балансам кошельков', value: PaymentTabEnum.WALLET, icon: ['fas', 'balance-scale'] },
  { title: 'Начисления и платежи', value: PaymentTabEnum.ACCRUALS, icon: ['fas', 'ruble-sign'] },
];

export const PAYMENT_TOOLBAR_CONTROLS: any = [
  { title: 'Реестр оплат', value: PaymentTabEnum.REGISTRY, icon: ['fas', 'list-ul'] },
  { title: 'Баланс кошелька', value: PaymentTabEnum.WALLET, icon: ['fas', 'balance-scale'] },
  { title: 'Начисления и платежи', value: PaymentTabEnum.ACCRUALS, icon: ['fas', 'ruble-sign'] },
];
