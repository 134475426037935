import { TreeHelper } from '@app/shared/helpers/tree.helper';
import { ChatUserTree, ChatUserTreeWithNode } from '../models/chat.model';

// Собираем всех чилдренов ниже максимального уровня в объект с ключом id максимального уровня
const getDescendantsAtLevel = (tree: ChatUserTree, maxLevel: number): Record<number, ChatUserTree[]> => {
  const result: Record<number, ChatUserTree[]> = {};

  const traverse = (node: ChatUserTree, level: number) => {
    if (level === maxLevel) {
      result[node.id] = node.children || [];
    } else if (node.children) {
      for (const child of node.children) {
        traverse(child, level + 1);
      }
    }
  };

  traverse(tree, 1);

  Object.entries(result).forEach(([key, value]) => {
    result[+key] = TreeHelper.treeToArray(value).map((el) => {
      delete el.parent;
      el.children = [];
      return el;
    });
  });

  return result;
};

// За второй проход по дереву заменяем чилдренов у максимально глубокого узла на элемнты из объекта возвращаемого предыдущей функцией
const replaceChildrenAboveLevel = (
  tree: ChatUserTreeWithNode,
  result: Record<number, ChatUserTreeWithNode[]>,
  maxLevel: number,
  level: number = 1
): void => {
  if (level === maxLevel && result[+tree.id]) {
    tree.children = result[+tree.id];
  } else if (tree.children) {
    for (const child of tree.children) {
      replaceChildrenAboveLevel(child, result, maxLevel, level + 1);
    }
  }
};

export const limitMaxTreeLevel = (tree: ChatUserTree, maxLevel: number): void => {
  const result = getDescendantsAtLevel(tree, maxLevel);
  replaceChildrenAboveLevel(tree, result, maxLevel);
};
