import { debounceTime, takeUntil } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { AuthService } from '@app/shared/services/auth.service';
import { ChatService } from '@app/chat/services/chat.service';
import { SocketDataService } from '@app/services/socket-data.service';
import { AttachedFile, ChatMessage, ChatRoom } from '@app/chat/models/chat.model';
import { FileManagerService } from '@app/file_manager/services/file_manager.service';

import saveAs from 'file-saver';
import { User } from '@app/shared/models/user.model';
import { DestroyService } from '@app/services/destroy.service';
import { merge, Subject } from 'rxjs';
import { tap } from 'rxjs/internal/operators';

@Component({
  selector: 'app-chat-messages',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss'],
  providers: [DestroyService],
})
export class ChatMessagesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('scrollMe') private scrollMe: ElementRef;
  @ViewChildren('messageElement') messageElements!: QueryList<ElementRef>;
  private intersectionObserver: IntersectionObserver;

  messages: ChatMessage[] = [];
  roomSelected: ChatRoom;
  userId = Number(this.authService.user_id);
  isMessagesLoading: boolean = false;
  scrollHeight = 0;
  count: number;

  isTradeGroup = this.chatService.isTradeGroup;
  protected timezoneOffset = this.authService.getTimezoneOffset();

  private readonly expandedMessages = new Set<number>();

  private unreadMessageIds: number[] = [];
  private messageReadSubject = new Subject();

  constructor(
    private chatService: ChatService,
    private chatDataService: SocketDataService,
    private authService: AuthService,
    private fileManagerService: FileManagerService,
    private readonly destroy$: DestroyService
  ) {
    this.chatService.contactSelectedChanged.pipe(takeUntil(this.destroy$)).subscribe((roomSelected) => {
      const isRoomChanged = roomSelected.room_id !== this.roomSelected?.room_id;

      this.roomSelected = roomSelected || ({ id: null } as ChatRoom);
      this.isMessagesLoading = isRoomChanged;

      if (!isRoomChanged) return;
      this.messages = [];

      if (!this.roomSelected?.room_id) return;
      this.chatDataService.loadPreviousMessages({ room_id: this.roomSelected.room_id });
    });

    this.messageReadSubject
      .pipe(
        debounceTime(5000),
        tap(() => {
          const idsToMarkRead = new Set(this.unreadMessageIds);
          const maxId = Math.max(...idsToMarkRead);

          if (maxId > this.roomSelected.last_read_message_id) {
            this.chatDataService.setMessageRead(this.roomSelected.room_id, [...idsToMarkRead]);
          }

          this.unreadMessageIds = [];
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnInit() {
    merge(this.chatService.contactsChanged, this.chatService.themesChanged, this.chatService.groupsChanged)
      .pipe(takeUntil(this.destroy$))
      .subscribe((contacts) => {
        const roomSelected = contacts[this.roomSelected.room_id];

        if (!roomSelected?.room_id) return;
        this.roomSelected = roomSelected;
      });

    this.chatService.messagesChanged.pipe(takeUntil(this.destroy$)).subscribe((messages) => {
      this.roomSelected = this.chatService.getContactSelected() || ({ id: null } as ChatRoom);

      const { room_id } = this.roomSelected;
      if (!messages[room_id]) return;

      this.unreadMessageIds = [];
      this.updateMessages(room_id, messages[room_id].list || []);
      this.updateScroll();
    });
  }

  ngAfterViewInit(): void {
    this.initializeIntersectionObserver();
  }

  ngOnDestroy() {
    this.intersectionObserver.disconnect();
  }

  private updateScroll() {
    if (this.isMessagesLoading) {
      this.isMessagesLoading = false;
      setTimeout(() => {
        if (!this.roomSelected) return;
        if (this.roomSelected.last_read_message_id == null) this.scrollToBottom();
        if (this.roomSelected.last_read_message_id === 0) this.scrollToTop();
        if (this.roomSelected.last_read_message_id > 0) this.scrollToUnread();
      }, 0);
    } else {
      if (this.isScrolledToBottom()) {
        setTimeout(() => this.scrollToBottom(), 0);
      }
    }
  }

  private scrollToTop() {
    const container = this.scrollMe.nativeElement;
    container.scrollTop = 0;
  }

  private scrollToBottom() {
    const container = this.scrollMe.nativeElement;
    container.scrollTop = container.scrollHeight;
  }

  private scrollToUnread() {
    const lastReadIndex = this.messages.findIndex((message) => message.id === this.roomSelected.last_read_message_id);

    if (lastReadIndex === -1) return;
    const lastReadMessage = this.messages[lastReadIndex];

    if (!lastReadMessage) return;
    const lastReadElement = this.scrollMe.nativeElement.querySelector(`[data-id="${lastReadMessage.id}"]`);

    if (!lastReadElement) return;
    this.scrollMe.nativeElement.scrollTop = lastReadElement.offsetTop;
  }

  private isScrolledToBottom(): boolean {
    const { scrollTop, scrollHeight, clientHeight } = this.scrollMe.nativeElement;
    return scrollTop + clientHeight >= scrollHeight - 10; // 10px погрешность от низа контейнера, с которой можно считать что скролл внизу.
  }

  updateMessages(room_id: string, messages: ChatMessage[]) {
    this.messages = JSON.parse(JSON.stringify(messages));
    this.count = this.chatService.getMessagesRoomStore(room_id).count;

    const tradeGroup = this.chatService.getGroups()[`dg-${this.roomSelected.group_id}`];

    this.messages.forEach((message) => {
      if (tradeGroup) {
        if (message.flags && message.flags.trade_provider && !tradeGroup.providers.includes(this.userId)) {
          message.author = {
            second_name: 'Поставщик',
          } as User;
        }

        if (message.flags && message.flags.trade_customer && !tradeGroup.customers.includes(this.userId)) {
          message.author = {
            second_name: 'Заказчик',
          } as User;
        }
      }

      // TODO: почему-то flags стало приходить пустое
      if (message.author.flags?.is_tso) {
        message.author.second_name = `${message.author.id}, ${message.author.second_name}`;
      }
    });
  }

  // showMoreMessages() {
  //   this.skipScroll = true;
  //
  //   this.chatDataService.loadPreviousMessages({
  //     room_id: this.roomSelected.room_id,
  //   });
  // }

  downloadFile(file: AttachedFile) {
    this.fileManagerService.downloadFile(file.url).subscribe((blob) => {
      saveAs(blob, `${file.filename}`);
    });
  }

  onRemoveMessage(message: ChatMessage) {
    this.chatDataService.deleteMessage(message.id);
  }

  onReplyMessage(message: ChatMessage) {
    // todo
  }

  onForwardMessage(message: ChatMessage) {
    // todo
  }

  protected isOverflowing(messageContent: HTMLElement) {
    return messageContent.scrollHeight > messageContent.clientHeight;
  }

  protected toggleExpand(message: ChatMessage) {
    if (this.expandedMessages.has(message.id)) {
      this.expandedMessages.delete(message.id);
    } else {
      this.expandedMessages.add(message.id);
    }
  }

  protected isExpanded(message: ChatMessage) {
    return this.expandedMessages.has(message.id);
  }

  protected trackById(_: number, message: ChatMessage) {
    return message.id;
  }

  private initializeIntersectionObserver() {
    this.intersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) return;
          const messageId = Number(entry.target.getAttribute('data-id'));
          // console.log(`Message ${messageId} is visible`);

          const lastReadMessageId = this.roomSelected.last_read_message_id;

          if (lastReadMessageId == null || lastReadMessageId > messageId) return;
          this.markMessageAsRead(messageId);
        });
      },
      {
        root: this.scrollMe.nativeElement,
        threshold: 0.5,
      }
      /* threshold - % размеров объекта, который попал в область видимости контейнера
       * в нашем случае 0.5 - сообщение видимое на половину считается прочитанным
       * */
    );

    this.messageElements.changes.pipe(takeUntil(this.destroy$)).subscribe((elements: QueryList<ElementRef>) => {
      this.intersectionObserver.disconnect();

      elements.forEach((element) => {
        this.intersectionObserver.observe(element.nativeElement);
      });
    });
  }

  private markMessageAsRead(messageId: number) {
    this.unreadMessageIds.push(messageId);

    if (this.unreadMessageIds.length !== 1) return;
    this.messageReadSubject.next();
  }
}
