import { ROLES } from './constants/roles.constants';
import { UserTypes } from './types/user.types';
import { User } from './models/user.model';
import { isArray, isNull } from 'lodash-es';

export function getShortTitleRole(roleValue: UserTypes): string {
  const targetRole = ROLES.find((role) => role.value === roleValue);

  return (targetRole && targetRole.shortTitle) || '-';
}

export function getTitleRole(roleValue: UserTypes): string {
  const targetRole = ROLES.find((role) => role.value === roleValue);

  return (targetRole && targetRole.title) || '-';
}

export function getFullTitleRole(roleValue: UserTypes): string {
  const targetRole = ROLES.find((role) => role.value === roleValue);

  return (targetRole && targetRole.fullTitle) || '-';
}

export function mapOrder(array, order, key) {
  if (!array || (array && !array.length)) {
    return [];
  }
  array.sort((a, b) => {
    const A = a[key],
      B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });

  return array;
}

export function mapToObject(request: {}): { [param: string]: any } {
  const result: { [param: string]: string } = {};
  const keys = Object.keys(request);
  for (const key of keys) {
    if (key === 'filter' && request[key]) {
      result[key] = JSON.stringify(request[key]);
    } else {
      if (!isNull(request[key])) {
        if (typeof request[key] !== 'undefined' && request[key] !== '' && request[key] !== null) {
          result[key] = request[key].toString();
        } else {
          delete result[key];
        }
      }
      if (isArray(request[key])) {
        if (request[key].length) {
          result[key] = request[key].join(',');
        } else {
          delete result[key];
        }
      }
    }
  }

  return result;
}

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function getClipboardData(e): string {
  let clipboardData, pastedData;

  e.stopPropagation();
  e.preventDefault();

  clipboardData = e.clipboardData;
  pastedData = clipboardData.getData('Text');

  return pastedData;
}

export function offset(el) {
  const rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

export function getFullInitials(user: User) {
  return `${user.second_name || ''} ${user.first_name || ''} ${user.patronymic || ''}`;
}

export function sortNumber<T>(a: T, b: T, field: string): number {
  if (a[field] > b[field]) return 1;
  if (a[field] === b[field]) return 0;
  if (a[field] < b[field]) return -1;
}

// Used to sort by years and months in the payment register table. Now sorting has been moved to the back.
export function multiFieldSort(fields: any) {
  return (a, b) => {
    return fields
      .map((param) => {
        let dir = 1;
        if (param[0] === '-') {
          dir = -1;
          param = param.substring(1);
        }
        if (a[param] > b[param]) {
          return dir;
        }
        if (a[param] < b[param]) {
          return -dir;
        }
        return 0;
      })
      .reduce((p, n) => (p ? p : n), 0);
  };
}

export function filterToParams<T>(filter: Partial<T>): { [param: string]: string | string[] } {
  return Object.keys(filter).reduce((p, k) => {
    const value = filter[k];
    if (value === undefined) {
      return p;
    }
    if (Array.isArray(value)) {
      if (!value.length) {
        return p;
      }
      p[k] = value.join(',');
      return p;
    }
    p[k] = '' + value;
    return p;
  }, {});
}

export function getEmployeeName(second_name, first_name, patronymic): string {
  if (!second_name) return 'unknown';
  return `${second_name} ${first_name ? first_name[0] : ''}.${patronymic ? patronymic[0] + '.' : ''}`;
}
