import * as moment from 'moment';
import {
  BACKEND_DATE_FORMAT,
  BACKEND_DATE_TIME_FORMAT,
  DATE_FORMAT,
  DATE_SHORT_FORMAT,
  DATE_TIME_SEPARATOR,
  SHORT_DATE_FORMAT,
  TABLE_DATE_FORMAT,
  TIME_FORMAT,
} from '../constants/date.constants';

export class DatesHelper {
  /**
   * Устанавливает дату в формат DD.MM.YYYY
   * @param {string} date
   * @param {number} offset
   * @returns {string}
   */
  static formatDate(date: string | Date, offset?: number): string {
    if (!offset) {
      return moment(date).format(DATE_FORMAT);
    } else {
      return moment(date).add(offset, 'minutes').format(DATE_FORMAT);
    }
  }

  static formatDateShort(date: string | Date, offset?: number): string {
    if (!offset) {
      return moment(date).format(DATE_SHORT_FORMAT);
    } else {
      return moment(date).add(offset, 'minutes').format(DATE_SHORT_FORMAT);
    }
  }

  static formatTableDate(date: string, offset?: number) {
    return moment(date).format(TABLE_DATE_FORMAT);
  }

  /**
   * Устанавливает дату в формат HH:mm
   * @param {string} date
   * @param {number} offset
   * @returns {string}
   */
  static formatTime(date: string | Date, offset?: number): string {
    if (!offset) {
      return moment(date).format(TIME_FORMAT);
    } else {
      return moment(date).add(offset, 'minutes').format(TIME_FORMAT);
    }
  }

  /**
   * Устанавливает дату в формат DD.MM.YYYY HH:mm
   * @param {string} date
   * @param {number} offset
   * @returns {string}
   */
  static formatDateTime(date: string, offset?: number) {
    if (!offset) {
      return moment(date).format(`${DATE_FORMAT} ${TIME_FORMAT}`);
    } else {
      return moment(date).add(offset, 'minutes').format(`${DATE_FORMAT} ${TIME_FORMAT}`);
    }
  }

  static formatDateTimeShort(dateString: string, offset?: number) {
    const date = moment(dateString);

    if (offset) {
      date.add(offset, 'minutes');
    }

    return moment(date).format(`${SHORT_DATE_FORMAT} ${TIME_FORMAT}`);
  }

  static formatDateTimeShortLineBreak(date: string, offset?: number) {
    return moment(date).format(`${SHORT_DATE_FORMAT} [\n] ${TIME_FORMAT}`);
  }
  /**
   * Оффсет в минутах от UTC для пользователя
   * @returns {number}
   */
  static getLocalTimezoneOffset(): number {
    return -new Date().getTimezoneOffset();
  }

  static getDateWithOffset(date: string): number {
    return +new Date(+new Date(date) + this.getLocalTimezoneOffset() * 60000);
  }

  static getDateSeparatorTimeFormat(): string {
    return `${BACKEND_DATE_FORMAT}${DATE_TIME_SEPARATOR}${TIME_FORMAT}`;
  }

  static isBetweenPeriod(
    date: moment.MomentInput,
    periodStartDate: moment.MomentInput,
    periodEndDate: moment.MomentInput,
    granularity: moment.unitOfTime.StartOf = 'ms',
    inclusivity: '[]' | '()' | '[)' | '(]' = '()'
  ): boolean {
    return moment(date).isBetween(periodStartDate, periodEndDate, granularity, inclusivity);
  }

  static fromShortDateToServerDateTime(value: string) {
    return moment(value, SHORT_DATE_FORMAT).format(BACKEND_DATE_TIME_FORMAT);
  }

  static fromShortDateToServerDate(value: string) {
    return moment(value, SHORT_DATE_FORMAT).format(BACKEND_DATE_FORMAT);
  }

  static fromDateToServerDate(value: string, format = SHORT_DATE_FORMAT) {
    return moment(value, format).isValid() ? moment(value, format).format(BACKEND_DATE_FORMAT) : value;
  }

  static getUtcDateTime(date: Date | string, time: Date | string, offset: number) {
    return !time
      ? moment.parseZone(date + '+00:00', BACKEND_DATE_TIME_FORMAT + 'Z')
      : moment
          .parseZone(`${date} ${time} ${DatesHelper.getCurrentOffsetStr(offset)}`, `${DATE_FORMAT} ${TIME_FORMAT} Z`)
          .utc();
  }

  static getCurrentOffsetStr(offset: number) {
    const offsetHours = Math.abs(offset / 60);
    return `${offset < 0 ? '-' : '+'}${offsetHours > 9 ? offsetHours : '0' + offsetHours}:00`;
  }
}
