import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationsService } from 'angular2-notifications';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import printJS from 'print-js';
import saveAs from 'file-saver';
import { AuthService } from '@app/shared/services/auth.service';
import { PaymentRegistryService } from './payment-registry.service';
import { paymentRegistryColumns, paymentRegistryColumnsByUserType } from '../constants/payment-registry.constants';
import {
  AggregationDataGeneral,
  BaseAggregationDataPayer,
  PaymentRegistryReportOptions,
} from '../models/payment-registry.model';
import { PaymentRegistryFilter } from '../models/payment-registry-filter.model';
import { UserTypes } from '@app/shared/types/user.types';
import { multiFieldSort } from '@app/shared/utils';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { PaymentTabEnum } from '../constants/payment.constants';
import { PersonalDataService } from '@app/shared/services/personal-data.service';
import { PaymentRegisterSettings } from '@app/shared/models/user-settings.model';
import { GeneralWalletAggregation, GeneralWalletFilter } from '../models/wallet-balance.model';
import { PaymentState } from '../models/payment-state.model';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { paymentAccrualsColumns } from '../constants/payment-accruals.constants';
import { GeneralAccrualAggregation, GeneralAccrualFilter } from '../models/payment-accrual.model';
import { paymentWalletColumns } from '../constants/payment-wallet.constants';
import { InitialsPipe } from '@app/shared/pipes/users/initials.pipe';
import * as moment from 'moment';
import { PaymentPdfService } from './payment-pdf.service';

@Injectable()
export class PaymentRegistryDataService {
  state$: Observable<PaymentState>;
  private stateSubject = new BehaviorSubject<PaymentState>({
    data: {
      registry: PaymentRegistryDataService.emptyData(),
      wallet: PaymentRegistryDataService.emptyDataWallet(),
    },
    activeTab: null,
    loading: false,
    active: false,
    editable: false,
    modeAccrual: '',
    type: null,
    nameUser: '',
    userId: null,
    filter: {
      registry: {},
      wallet: {},
      accruals: {},
    },
    columns: {
      registry: [],
      wallet: [],
      accruals: [],
    },
    displayedColumns: {},
    actions: {
      balanceUp: false,
      editToggle: false,
    },
  });
  private actionsSubject = new Subject<{ type: string; payload?: any }>();

  private static emptyData(): AggregationDataGeneral {
    return {} as AggregationDataGeneral;
  }

  private static emptyDataWallet(): GeneralWalletAggregation {
    return {} as GeneralWalletAggregation;
  }

  private static emptyDataAccruals() {
    return [] as GeneralAccrualAggregation[];
  }

  constructor(
    private paymentRegistry: PaymentRegistryService,
    private notify: NotificationsService,
    private personalDataService: PersonalDataService,
    private auth: AuthService,
    private paymentPdfService: PaymentPdfService
  ) {
    this.state$ = this.stateSubject.asObservable();
    this.actionsSubject
      .asObservable()
      .pipe(filter((action) => ['init', 'filter'].includes(action.type)))
      .pipe(
        switchMap(() => this.personalDataService.getUserSettings()),
        tap((setting) => {
          this.dispatch({ type: 'setSetting', payload: setting });
        })
      )
      .pipe(withLatestFrom(this.state$))
      .pipe(
        map((value) => value),
        switchMap(([action, state]) => {
          console.log('!state!', state);
          /** TODO: Временно добавлено сброс фильтра state.filter.accruals = {},
                    пока не сделан будет фильтр, чтоб не ломалась таблица */
          state.filter.accruals = {};
          switch (state.activeTab) {
            case PaymentTabEnum.ACCRUALS: {
              return this.requestAccrual({
                filter: state.filter.accruals,
                type: state.type,
              })
                .pipe(
                  map((payload: any) => {
                    return { type: 'updateAccrual', payload };
                  })
                )
                .pipe(
                  catchError((e) => {
                    state.loading = false;
                    state.data.accruals = [];
                    this.handleError(e);
                    return EMPTY;
                  })
                );
            }
            case PaymentTabEnum.REGISTRY: {
              return this.requestRegistry({
                filter: state.filter.registry,
                type: state.type,
              }).pipe(
                map((payload: any) => {
                  return {
                    type: 'update',
                    payload: payload ? payload : {},
                  };
                }),
                catchError((e) => {
                  this.handleError(e);
                  state.data.registry = {} as AggregationDataGeneral;
                  state.loading = false;
                  return EMPTY;
                })
              );
            }
            case PaymentTabEnum.WALLET: {
              return this.requestWallet({
                filter: state.filter.wallet,
                type: state.type,
              })
                .pipe(
                  map((payload: any) => {
                    return { type: 'updateWallet', payload };
                  })
                )
                .pipe(
                  catchError((e) => {
                    this.handleError(e);
                    state.loading = false;
                    return EMPTY;
                  })
                );
            }
          }
        })
      )
      .subscribe((action) => {
        this.dispatch(action);
      });

    this.auth.userStream.subscribe((payload) => {
      this.dispatch({ type: 'changeUser', payload });
    });
  }

  init() {
    this.dispatch({ type: 'init' });
  }

  onToggleEditable() {
    this.dispatch({ type: 'toggleEditable' });
  }

  onFilterChange(payload: {
    registry?: Partial<PaymentRegistryFilter>;
    accruals?: Partial<GeneralAccrualFilter>;
    wallet?: Partial<GeneralWalletFilter>;
  }) {
    this.dispatch({ type: 'filter', payload });
  }

  onDisplayedColumnsChange(payload) {
    this.dispatch({ type: 'displayedColumns', payload });
  }

  onColumnsChange(payload) {
    this.dispatch({ type: 'columns', payload });
  }

  onChangeTab(payload) {
    this.dispatch({ type: 'changeTab', payload });
  }

  onRemovingRestriction({ byPayer, checked }: { byPayer: BaseAggregationDataPayer; checked: HTMLInputElement }) {
    const status = checked ? 'manual_active' : 'manual_blocked';
    this.paymentRegistry
      .updateRestrictionsTrade({
        status,
        user_payer_id: byPayer.payer.id,
      })
      .subscribe(
        () => {
          this.dispatch({ type: 'updateByPayer', payload: { byPayer, changes: { removing_restrictions: status } } });
        },
        (error) => this.handleError(error)
      );
  }

  pdfReport(options: PaymentRegistryReportOptions) {
    this.paymentRegistry
      .pdfReport(options)
      .pipe(switchMap(({ url }) => this.paymentRegistry.downloadPdf(url)))
      .subscribe(
        (response) => {
          const fileName = options.nameFile ? options.nameFile + '.pdf' : 'document.pdf';
          saveAs(response.body, fileName);
        },
        (error) => this.handleError(error)
      );
  }

  printTable(options: PaymentRegistryReportOptions) {
    this.paymentRegistry
      .pdfReport(options)
      .pipe(switchMap(({ url }) => this.paymentRegistry.downloadPdf(url)))
      .subscribe(
        (response) => {
          printJS(URL.createObjectURL(response.body));
        },
        (error) => this.handleError(error)
      );
  }

  private handleError(error: HttpErrorResponse) {
    this.notify.error('Ошибка!', error.message || error.status, {
      clickToClose: true,
    });
  }

  private dispatch(action: { type: string; payload?: any }) {
    const prev = this.stateSubject.value;
    const state = this.reduce(prev, action);
    if (!state) {
      return;
    }
    if (
      action.type === 'changeTab' ||
      action.type === 'filter' ||
      // action.type === 'columns' ||
      action.type === 'displayedColumns'
    ) {
      this.updateUserSetting(state, action).subscribe((res) => {
        this.actionsSubject.next(action);
      });
    } else {
      this.actionsSubject.next(action);
    }
    this.stateSubject.next(state);
  }

  private reduce(state: PaymentState, { type, payload }: { type: string; payload?: any }): PaymentState {
    switch (type) {
      case 'init': {
        return {
          ...state,
          data: {
            registry: PaymentRegistryDataService.emptyData(),
            wallet: PaymentRegistryDataService.emptyDataWallet(),
            accruals: PaymentRegistryDataService.emptyDataAccruals(),
          },
          editable: true,
          loading: true,
          filter: {
            registry: { ...state.filter.registry },
            wallet: { ...state.filter.wallet },
            accruals: { ...state.filter.accruals },
          },
        };
      }
      case 'setSetting': {
        const filters =
          payload.payment_register_settings.payment_register?.filters?.reduce((acc, item) => {
            acc[item.name] = item.filter[item.name];
            return acc;
          }, {}) || {};
        const filtersTransactions =
          payload.payment_register_settings.transactions?.filters?.reduce((acc, item) => {
            acc[item.name] = item.filter[item.name];
            return acc;
          }, {}) || {};
        const hiddenColumns =
          payload.payment_register_settings.payment_register?.hidden_columns?.reduce((acc, column) => {
            acc[column] = false;
            return acc;
          }, {}) || {};

        const registryColumns = state.columns.registry.map((column) => {
          const filterKeys = this.getFilterKeys(column);
          const isFilterActive = filterKeys.some((k) => filters[k] !== undefined && filters[k] !== null);
          if (!!column.isFilterActive === isFilterActive) {
            return column;
          }
          return { ...column, isFilterActive };
        });

        return {
          ...state,
          loading: true,
          activeTab:
            !state.activeTab && payload.payment_register_settings?.active_tab
              ? payload.payment_register_settings.active_tab
              : state.activeTab || PaymentTabEnum.REGISTRY,
          displayedColumns: { ...state.displayedColumns, ...hiddenColumns },
          filter: {
            registry: { ...state.filter.registry, ...filters },
            wallet: { ...state.filter.wallet },
            accruals: { ...state.filter.accruals, ...filtersTransactions },
          },
          columns: { ...state.columns, ...{ registry: registryColumns } },
        };
      }
      case 'changeTab': {
        return {
          ...state,
          activeTab: payload,
        };
      }
      case 'toggleEditable':
        return {
          ...state,
          editable: !state.editable,
        };
      case 'filter':
        return {
          ...state,
          filter: { ...state.filter, ...payload },
          loading: true,
        };
      case 'changeUser':
        const initialsPipe = new InitialsPipe();
        if (!payload) {
          return {
            ...state,
            type: null,
            columns: {
              registry: [],
              accruals: [],
              wallet: [],
            },
            displayedColumns: {},
            actions: {
              balanceUp: false,
              editToggle: false,
            },
          };
        }
        return {
          ...state,
          ...this.getColumns(payload.type),
          type: payload.type,
          nameUser: initialsPipe.transform(payload),
          userId: payload.id,
          actions: {
            balanceUp: payload.type === RolesEnum.ADMIN_OF_USER || payload.type === RolesEnum.ADMIN_OF_DIRECTION,
            editToggle: payload.type === RolesEnum.SUPERUSER || payload.type === RolesEnum.ACCOUNTANT,
          },
        };
      case 'update':
        return {
          ...state,
          data: { registry: payload },
          loading: false,
        };
      case 'updateAccrual':
        return {
          ...state,
          data: { accruals: payload },
          loading: false,
        };
      case 'updateWallet':
        return {
          ...state,
          data: { wallet: payload },
          loading: false,
        };
      case 'columns':
        return {
          ...state,
          columns: {
            ...state.columns,
            registry: payload,
            wallet: payload.wallet,
          },
        };
      case 'displayedColumns':
        return {
          ...state,
          displayedColumns: payload,
        };
      case 'updateByPayer':
        return {
          ...state,
          ...this.updateByPayer(state, payload),
        };
    }
  }

  private updateByPayer(state: PaymentState, { byPayer, changes }): Partial<PaymentState> {
    let newState = {};
    for (let j = 0; j < state.data.registry.aggregation_of_data_by_month?.length; j++) {
      const byMonth = state.data.registry.aggregation_of_data_by_month[j];
      for (let k = 0; k < byMonth.aggregation_of_data_by_structure_ap?.length; k++) {
        const byStructure = byMonth.aggregation_of_data_by_structure_ap[k];
        const byPayerIndex: number = byStructure.aggregation_of_data_by_payer.indexOf(byPayer);
        newState = byStructure.aggregation_of_data_by_payer.map((payer) => {
          if (byPayer.payer.id === payer.payer.id) {
            payer.removing_restrictions = changes.removing_restrictions;
          }
        });
      }
    }
    return newState;
  }

  private getColumns(type): any {
    const columnIds = paymentRegistryColumnsByUserType[type];
    if (!columnIds) {
      return { columns: [], displayedColumns: [] };
    }
    const columns = paymentRegistryColumns.filter((c) => columnIds.some((id) => c.id === id));
    const displayedColumns = paymentRegistryColumns.reduce((p, c) => {
      p[c.id] = columnIds.some((id) => c.id === id);
      return p;
    }, {});
    return {
      columns: { registry: columns, accruals: paymentAccrualsColumns, wallet: paymentWalletColumns },
      displayedColumns,
    };
  }

  private updateUserSetting(state: PaymentState, action) {
    console.log('STATE !!!', state);
    const paymentRegisterSettings: PaymentRegisterSettings = {
      active_tab: state.activeTab,
      payment_register: {
        hidden_columns: Object.keys(state.displayedColumns).filter((item) => !state.displayedColumns[item]),
        filters: Object.entries(state.filter?.registry || {})
          ?.map(([key, value]) => ({ name: key, filter: { [key]: value } }))
          .filter((item) => item.filter[item.name]),
      },
      wallet_balance_report: {
        hidden_columns: Object.keys(state.displayedColumns).filter((item) => !state.displayedColumns[item]),
        filters: Object.entries(state.filter?.wallet || {})
          ?.map(([key, value]) => ({ name: key, filter: { [key]: value } }))
          .filter((item) => item.filter[item.name]),
      },
      transactions: {
        filters: Object.entries(state.filter?.accruals || {})
          ?.map(([key, value]) => ({ name: key, filter: { [key]: value } }))
          .filter((item) => item.filter[item.name]),
      },
    };
    return this.personalDataService.getUserSettings().pipe(
      switchMap((setting) => {
        return this.personalDataService.updateUserSettings({
          ...setting,
          payment_register_settings: paymentRegisterSettings,
        });
      })
    );
  }

  public onDownloadDocument(options: any) {
    const { type, payerId, month, year } = options;
    this.paymentRegistry
      .getFileByPayerId(payerId, month, year, type)
      .pipe(
        map((fileBlob) => {
          const fileName = `${type === 'aps' ? 'Акт' : 'СФ'}_${year}_${month}_${payerId}.pdf`;
          const link = URL.createObjectURL(new Blob([fileBlob], { type: 'application/pdf' }));
          return { fileName, link };
        }),
        switchMap(({ fileName, link }) => {
          return this.paymentPdfService.viewDoc(link, fileName);
        })
      )
      .subscribe(
        () => {},
        (error) => {
          console.log(error);
        }
      );
  }

  private requestRegistry(params: {
    filter: Partial<PaymentRegistryFilter>;
    type: UserTypes;
  }): Observable<AggregationDataGeneral> {
    const currMonth = moment().month();
    if (params.type === RolesEnum.SUPERUSER || params.type === RolesEnum.ACCOUNTANT) {
      return this.paymentRegistry.getPaymentRegistry(params.filter).pipe(
        map((item) => {
          if (item && item.aggregation_of_data_by_month && item.aggregation_of_data_by_month.length) {
            item.aggregation_of_data_by_month.forEach((value) => (value.isEditable = true));
            return item;
          } else {
            return {} as AggregationDataGeneral;
          }
        })
      );
    }
    if (params.type === RolesEnum.PARTNER) {
      return this.paymentRegistry.getAgentWallet(params.filter).pipe(
        map((item) => {
          if (item && item.aggregation_of_data_by_month && item.aggregation_of_data_by_month.length) {
            item.aggregation_of_data_by_month.forEach((value) => {
              value.isEditable = value.month === currMonth;
            });
            return item;
          } else {
            return {} as AggregationDataGeneral;
          }
        })
      );
    }
    return this.paymentRegistry.getCustomerWallet(params.filter).pipe(
      map((item) => {
        if (item && item.aggregation_of_data_by_month && item.aggregation_of_data_by_month.length) {
          item.aggregation_of_data_by_month.forEach((value) => {
            value.isEditable = value.month === currMonth;
          });
          return item;
        } else {
          return {} as AggregationDataGeneral;
        }
      })
    );
  }

  private requestWallet(params: { filter: Partial<any>; type: UserTypes }): Observable<GeneralWalletAggregation> {
    if (params.type === RolesEnum.SUPERUSER || params.type === RolesEnum.ACCOUNTANT) {
      return this.paymentRegistry.getWalletFull(params.filter);
    }
    if (params.type === RolesEnum.PARTNER) {
      return this.paymentRegistry.getWalletAgent(params.filter);
    }
    return this.paymentRegistry.getWalletCustomer(params.filter);
  }

  private requestAccrual(params: { filter: Partial<GeneralAccrualFilter>; type: UserTypes }): Observable<any> {
    return this.paymentRegistry.getAccrual(params.filter);
  }

  private getFilterKeys(column) {
    if (!column.filterKeys) {
      return [column.id];
    }
    if (column.filterKeys === 'fromTo') {
      return ['from_' + column.id, 'to_' + column.id];
    }
    return column.filterKeys;
  }
}
