import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError as observableThrowError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@app/environments/environment';
import { LockUserTabTrade, TradeCard, TradesRequestConfig } from '../models/trades.model';
import { CUSTOMER_TRADE_COLUMNS } from '../constants/trades-table.constants';
import { catchError, filter, map } from 'rxjs/operators';
import { DatesHelper } from '@app/shared/helpers/dates.helper';
import { SupplierTradeCard } from '../models/suppliers.model';
import { TradesHelper } from '../helpers/trades.helper';
import { InviteSupplierStatuses } from '../constants/suppliers.constants';
import { Router } from '@angular/router';
import { Column } from '@app/shared/models/table';
import { mapToObject } from '@app/shared/utils';
import { TradeCardTabConfig } from '../models/trade-card-tabs.model';
import { CUSTOMER_TRADE_CARD_MAIN_TABS, TradeCardTabsRoutes } from '../constants/trade-tabs.constants';
import { AuthService } from '@app/shared/services/auth.service';
import { TradesValidationService } from './trades-validation.service';
import { TradeCardStatuses } from '../constants/trades.constants';
import { AbstractTradeService } from './abstract-trades.service';

@Injectable()
export class TradesService extends AbstractTradeService {
  private customerTradeColumns: Column<TradesRequestConfig>[] = CUSTOMER_TRADE_COLUMNS;

  private _tradeCardStream$: BehaviorSubject<TradeCard> = new BehaviorSubject<TradeCard>(null);

  private _tradeCard: TradeCard = <TradeCard>{
    customers: [],
    temp_data: {
      lots: [],
    },
  };

  private _tradeTab: TradeCardTabConfig = <TradeCardTabConfig>{};

  private _haveChanges$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _cardChanged$: BehaviorSubject<TradeCard> = new BehaviorSubject<TradeCard>(null);
  cardChanged$: Observable<TradeCard> = this._cardChanged$.asObservable().pipe(filter((c) => c !== null));

  private _reloadCard$ = new Subject<void>();
  reloadCard$: Observable<void> = this._reloadCard$.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private tradesValidationService: TradesValidationService
  ) {
    super();
  }

  reloadCard() {
    this._reloadCard$.next();
  }

  /**
   * Сохрананение стейта для доступа из других компонентов
   * @param {TradeCard} card
   */
  storeCard(card: TradeCard) {
    this.setHaveChanges(true);
    this._tradeCard = Object.assign({}, card);
    this._cardChanged$.next(this._tradeCard);
  }

  /**
   * Получение стейт кт
   * @returns {TradeCard}
   */
  getStoreCard() {
    return this._tradeCard;
  }

  /**
   * Сохранить стейт таба
   * @param {TradeCardTabConfig} tab
   */
  storeTab(tab: TradeCardTabConfig) {
    this._tradeTab = null;
    this._tradeTab = tab;
  }

  /**
   * Получить стейт таба
   * @returns {TradeCardTabConfig}
   */
  getTab(): TradeCardTabConfig {
    if (this._tradeTab && Object.keys(this._tradeTab).length) {
      return this._tradeTab;
    } else {
      return this.tradeTabs[0];
    }
  }

  /**
   * Сохраняем значение разницы от UTC владельца кт
   * @param {number} offset
   */
  set ownerTimezoneOffset(offset: number) {
    this._ownerTimezoneOffset = offset || this._ownerTimezoneOffset;
  }

  /**
   * Получаем значение разницы от UTC владельца кт
   * @returns {number}
   */
  get ownerTimezoneOffset() {
    return this._ownerTimezoneOffset;
  }

  /**
   * Если нужны данные о текущей карте торгов - подписываемся(subscribe) на tradeCardStream
   */
  get tradeCardStream$() {
    return this._tradeCardStream$.asObservable().pipe(filter((x) => x !== null));
  }

  /**
   * Для отслеживания состоянии редактирования контента в табе
   */
  get haveChanges$(): Observable<boolean> {
    return this._haveChanges$.asObservable();
  }

  /**
   * Изменяем состояние редактирования контента в табе
   * @param {boolean} state
   */
  setHaveChanges(state: boolean) {
    this._haveChanges$.next(state);
  }

  /**
   * Получение реестра торгов
   * @param requestConfig
   * @returns {Observable<TradeCard[]>}
   */
  getTrades(requestConfig?: TradesRequestConfig): Observable<TradeCard[]> {
    const fromObject = mapToObject(requestConfig || {});
    const params = new HttpParams({ fromObject });

    return this.http
      .get<TradeCard[]>(`${environment.api_url}/trades`, {
        headers: {
          Accept: 'application/vnd.api+json',
        },
        params,
      })
      .pipe(catchError((e) => observableThrowError(e)));
  }

  /**
   * Получение кт, сохранение стейта, оповещение об изменении кт
   * @param {number} cardId
   * @returns {Observable<TradeCard>}
   */
  getCard(cardId: number): Observable<TradeCard> {
    return this.http.get<TradeCard>(`${environment.api_url}/trade/${cardId}?include=customers.user.company,owner`).pipe(
      map((card) => {
        card.customers.forEach((item) => {
          TradesHelper.fillUsersInfo(item.user);
        });

        this.storeCard(card);
        this.setHaveChanges(false);
        this.updateTabsLocking(this.tradeTabs, card.locking);
        this.storeTab(this.getTab());

        this.nextTradeCardData(card);
        this.ownerTimezoneOffset = card.owner.timezone_offset;
        return card;
      }),
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  /**
   * Создание кт
   * @param {TradeCard} card
   * @returns {Observable<TradeCard>}
   */
  createCard(card: TradeCard): Observable<TradeCard> {
    if (card.customers && card.customers.length) {
      card.customers = TradesHelper.prepareUsersForSend(card.customers);
    }
    return this.http.post<TradeCard>(`${environment.api_url}/trades`, card).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  /**
   * Обновление кт
   * @param {Partial<TradeCard>} card
   * @returns {Observable<Partial<TradeCard>>}
   */
  updateCard(card: Partial<TradeCard>): Observable<Partial<TradeCard>> {
    if (card.customers && card.customers.length) {
      card.customers = TradesHelper.prepareUsersForSend(card.customers);
    }
    return this.http
      .put<Partial<TradeCard>>(`${environment.api_url}/trade/${card.id}`, {
        ...card,
      })
      .pipe(
        catchError((e) => {
          return observableThrowError(e);
        })
      );
  }

  /**
   * Выбрать исполнителя по торгам
   * @param tradeId
   * @param supplierId
   */
  selectProviderToTrade(tradeId: number, supplierId: number) {
    return this.http.post(`${environment.api_url}/trade/${tradeId}/selected-provider/${supplierId}`, {}).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  /**
   * Отменить выбор исполнителя по торгам
   * @param tradeId
   * @param supplierId
   */
  deselectProviderToTrade(tradeId: number, supplierId: number) {
    return this.http.delete(`${environment.api_url}/trade/${tradeId}/selected-provider/${supplierId}`).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  /**
   * Дать доступ к чату поставщику
   * @param tradeId
   * @param supplierId
   */
  giveSupplierChatAccess(tradeId: number, supplierId: number) {
    return this.http.post(`${environment.api_url}/trade/${tradeId}/chat-access-provider/${supplierId}`, {}).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  /**
   * Отозвать доступ к чату у поставщика
   * @param tradeId
   * @param supplierId chat-access-provider
   */
  revokeSupplierChatAccess(tradeId: number, supplierId: number) {
    return this.http.delete(`${environment.api_url}/trade/${tradeId}/chat-access-provider/${supplierId}`).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  /**
   * Создать шаблон карты торгов
   * @param card
   */
  createTemplate(card: TradeCard) {
    return this.http.post<TradeCard>(`${environment.api_url}/trades/template`, card).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  /**
   * Создать шаблон карты торгов на основе текущей
   * @param cardId
   * @param title
   */
  createTemplateByTrade(cardId: number, title: string) {
    return this.http
      .post<TradeCard>(`${environment.api_url}/trades/template/${cardId}`, {
        title,
        is_template_creation: true,
      })
      .pipe(
        catchError((e) => {
          return observableThrowError(e);
        })
      );
  }

  /**
   * Создать кт на основе шаблона
   * @param cardId
   * @param title
   */
  createTradeByTemplate(cardId: number, title: string) {
    return this.http
      .post<TradeCard>(`${environment.api_url}/trades/template/${cardId}`, {
        title,
        is_template_creation: false,
      })
      .pipe(
        catchError((e) => {
          return observableThrowError(e);
        })
      );
  }

  /**
   * Получение колонок для реестра торгов заказчика
   * @returns {Column[]}
   */
  getCustomerTableColumns(): Column<TradesRequestConfig>[] {
    return this.customerTradeColumns;
  }

  /**
   * Оповещение об изменении кт
   * @param {TradeCard} card
   */
  nextTradeCardData(card: TradeCard) {
    this.operateTradesTabValidation(card);
    this._tradeCardStream$.next(card);
  }

  /**
   * Получение табов заказчика
   * @returns {TradeCardTabConfig[]}
   */
  get tradeTabs() {
    return CUSTOMER_TRADE_CARD_MAIN_TABS;
  }

  /**
   * Обновление блокировок табов
   * @param {TradeCardTabConfig[]} tradeCardTabs
   * @param {LockUserTabTrade[]} locking
   */
  updateTabsLocking(tradeCardTabs: TradeCardTabConfig[], locking: LockUserTabTrade[]) {
    tradeCardTabs.forEach((tab) => {
      tab.isLocked = false;
      tab.isEdit = false;
      tab.lockedUserInfo = null;
    });

    tradeCardTabs.filter((tab) => {
      locking.forEach((lockTab) => {
        if (tab.lockId === lockTab.tab_trade && +lockTab.user_id === +this.authService.user_id) {
          tab.isLocked = false;
          tab.lockedUserInfo = null;
          tab.isEdit = true;
        }

        if (tab.lockId === lockTab.tab_trade && +lockTab.user_id !== +this.authService.user_id) {
          tab.isLocked = true;
          const customer = this.getStoreCard().customers.find(
            (customerFind) => +customerFind.user.id === +lockTab.user_id
          );
          const user = customer && customer.user;

          tab.lockedUserInfo = user;
        }
      });
    });
  }

  /**
   * Перейти на кт заказчика
   * @param id
   */
  navigateToCustomerCard(id: number) {
    this.router.navigate(['/trades/customer/card', id]);
  }

  /**
   * Посдсвечивание активных/нективных поставщиков
   * @param {SupplierTradeCard[]} trades
   */
  highLightTrades(trades: SupplierTradeCard[]) {
    if (!trades) {
      return;
    }
    trades.forEach((trade) => this.highLightTrade(trade));
  }

  highLightTrade(trade: SupplierTradeCard) {
    trade.hasSuggestion = this.hasSuggestion(trade);
  }

  operateTradesTabValidation(card: TradeCard) {
    this.tradesValidationService.tradeTabValidator$ = {
      id: TradeCardTabsRoutes.SEARCH_SUPPLIERS,
      invalid:
        !Array.isArray(card.providers_filter.data) ||
        !TradesHelper.hasSomeCompetenciesSelected(card.providers_filter.data),
    };

    this.tradesValidationService.tradeTabValidator$ = {
      id: TradeCardTabsRoutes.PURCHASE_SYSTEM,
      invalid: !card.lots || (card.lots && !card.lots.length),
    };

    const termOfBuildingsErrors = TradesHelper.checkTermOfBuildingValidation(
      card.trade_date,
      card.archive_date,
      this.authService.getTimezoneOffset()
    );

    this.tradesValidationService.tradeTabValidator$ = {
      id: TradeCardTabsRoutes.TERM_OF_BIDDING,
      invalid:
        termOfBuildingsErrors?.formError ||
        (termOfBuildingsErrors?.startDateError &&
          [TradeCardStatuses.TEMPLATE, TradeCardStatuses.PROJECT].includes(card.status)),
    };
  }
}
