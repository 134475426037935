import { takeUntil } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { ROLES, RolesEnum } from '@app/shared/constants/roles.constants';
import { AuthService } from '@app/shared/services/auth.service';
import { ChatService } from '../services/chat.service';
import { SocketDataService } from '../../services/socket-data.service';
import { ChatRoom, ChatSection, ChatUserTree } from '@app/chat/models/chat.model';
import { ChatSectionsEnum, TechTypeEnum } from '@app/chat/constants/chat-sections.constants';
import { UserCardComponent } from '@app/shared/components/user-card/user-card.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserCompetenceMapModalComponent } from '@app/+competence-map/competence-shared/user-competence-map/user-competence-map-modal/user-competence-map-modal.component';
import { User } from '@app/shared/models/user.model';

@Component({
  selector: 'app-chat-contacts',
  templateUrl: './chat-contacts.component.html',
  styleUrls: ['./chat-contacts.component.scss'],
})
export class ChatContactsComponent implements OnInit, OnDestroy {
  // Для загрузки тех групп и контактов
  @Input() techType: TechTypeEnum | null = null;
  techTypeEnum = TechTypeEnum;

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  roles = ROLES;
  rolesEnum = RolesEnum;

  contacts: ChatRoom[] = [];
  treeContacts: ChatRoom[] = [];
  chatSectionSelected: ChatSection;
  filterString: string;
  isContactsExpanded: boolean = true;
  chatSectionsEnum = ChatSectionsEnum;
  contactsOnlyFilter: { roles: string[]; competencies: number[] };
  groupSelected: ChatRoom;

  scrollPosition = 0;

  userTree: ChatUserTree;

  @Input() isTreeView = false;

  constructor(
    private chatService: ChatService,
    private chatDataService: SocketDataService,
    private authService: AuthService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.chatSectionSelected = this.chatService.getChatSectionSelected();
    this.contactsOnlyFilter = this.chatService.getContactsOnlyFilter();

    this.chatService.contactsFilterChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((filterString) => {
      this.filterString = filterString;
      this.removeSelf(this.chatService.getContacts());
    });

    this.removeSelf(this.chatService.getContacts());

    this.chatService.contactsChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((contacts) => this.removeSelf(contacts));

    this.chatService.groupSelectedChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((groupSelected) => {
      this.groupSelected = groupSelected;

      this.removeSelf(this.chatService.getContacts());
    });

    this.chatService.themeSelectedChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((themeSelected) => {
      this.groupSelected = themeSelected;

      this.removeSelf(this.chatService.getContacts());
    });

    this.chatService.chatSectionSelectedChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((chatSection) => (this.chatSectionSelected = chatSection));

    this.filterString = this.chatService.contactsFilter;
    this.chatService.contactsOnlyFilterChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((contactsOnlyFilter) => (this.contactsOnlyFilter = contactsOnlyFilter));

    this.chatService.userTreeChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.userTree = this.chatService.getUserTree();

      this.removeSelf(this.chatService.getContacts());
    });
    this.chatService.userTreeChanged.next();

    this.chatService.scrollPosition.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.scrollPosition = res;
    });
  }

  removeSelf(contacts) {
    delete contacts[+this.authService.user_id];
    this.filterContacts(contacts);
  }

  filterContacts(contacts) {
    this.contacts = [];
    Object.keys(contacts).forEach((room_id) => {
      this.contacts.push(contacts[room_id]);
    });

    if (this.chatSectionSelected.name === ChatSectionsEnum.TRADE) {
      this.filterForTrade();
    }
    this.sortContacts();

    if (this.userTree) {
      const tempTree = this.userTree.id === Number(this.authService.user_id) ? this.userTree.children : [this.userTree];
      const tempContacts = this.formatTreeChatRoom(tempTree)?.filter((item) => item !== undefined);
      if (tempContacts?.length) {
        this.treeContacts = tempContacts;
      }
    }

    if (
      this.chatSectionSelected.name === ChatSectionsEnum.ADMIN &&
      this.authService.user_type !== this.rolesEnum.SUPERUSER
    ) {
      this.contacts = [];
      Object.keys(contacts).forEach((item) => {
        if (contacts[item].type === this.rolesEnum.SUPERUSER) {
          this.contacts.push(contacts[item]);
        }
      });
    }
  }

  formatTreeChatRoom(userTree: ChatUserTree[]) {
    return userTree?.map((item) => {
      let children = [];
      if (item.children?.length) {
        children = item.children?.map(() => this.formatTreeChatRoom(item?.children));
      }
      const find = this.contacts.find((contact) => Number(contact.id) === item.id);
      if (find) find.children = children[0];
      return find;
    });
  }

  sortContacts() {
    this.contacts = this.contacts.sort((a, b) => {
      const firstComparison = a.type.trimStart().toLowerCase().localeCompare(b.type.trimStart().toLowerCase(), 'en');
      if (firstComparison !== 0) {
        return firstComparison;
      }
      return a.second_name.trimStart().toLowerCase().localeCompare(b.second_name.trimStart().toLowerCase(), 'en');
    });
  }

  filterForTrade() {
    if (!this.groupSelected || (this.groupSelected && !this.groupSelected.room_id)) {
      this.contacts = [];
      return;
    }

    if (this.groupSelected.section.name !== ChatSectionsEnum.TRADE) {
      this.contacts = [];
      return;
    }

    this.contacts = this.contacts.filter((contact) => this.groupSelected.users.includes(+contact.id));

    if (this.groupSelected.is_provider_in_trade && this.groupSelected.group_id) {
      this.contacts.push({
        second_name: this.groupSelected.providers.includes(+this.authService.user_id) ? 'Заказчики' : 'Поставщики',
        section: this.groupSelected.section,
        room_id: null,
      } as ChatRoom);
    }
  }

  onContactsExpand() {
    this.isContactsExpanded = !this.isContactsExpanded;
  }

  onFilterToggle() {
    this.chatService.toggleContactsOnlyFilter(true);
  }

  onClearFilter() {
    this.chatService.toggleContactsOnlyFilter(false);
    this.chatService.contactsOnlyFilterChanged.next({
      roles: [],
      competencies: [],
    });
  }

  get totalUnreadCounter() {
    return this.chatService.totalUnreadCount(this.contacts);
  }

  get chatAdminView() {
    return (
      (this.chatSectionSelected.name === this.chatSectionsEnum.ADMIN ||
        this.chatSectionSelected.name === this.chatSectionsEnum.TECH) &&
      !this.isTreeView
    );
  }

  isContactTooltipDisabled(name: string, role: string) {
    return name.length + role.length < 30;
  }

  identify(index, item) {
    return item.id;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
