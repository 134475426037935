import { Column, PaymentRegistryRequestConfig } from '../models/payment-registry-column.model';
import { MultiSelectListItem } from '@app/shared/models/multi-select-list-item';
import { StatusesEnum } from '@app/shared/constants/statuses.constants';

export enum PaymentAccrualsColumnsKeys {
  ID = 'id',
  TYPE = 'type',
  USER_ID = 'user_id',
  NAME = 'name_user_or_company',
  STATUS = 'user_status',
  TRADE_ID = 'trade_id',
  AMOUNT = 'amount',
  APPROVAL_DATE = 'approval_date',
  PAYMENT_ORDER_ID = 'payment_order_id',
  OPERATOR = 'user_created',
  META = 'meta',
  COMMENT = 'comment',
}

export const paymentAccrualsColumns: Column<PaymentRegistryRequestConfig>[] = [
  {
    id: PaymentAccrualsColumnsKeys.ID,
    label: 'ID операции',
    isEnabledSearch: true,
    width: 150,
    checkbox: true,
  },
  {
    id: PaymentAccrualsColumnsKeys.TYPE,
    label: 'Тип операции',
    isEnabledFilter: true,
    width: 170,
    filter: 'filter',
  },
  {
    id: PaymentAccrualsColumnsKeys.USER_ID,
    label: 'ID пользователя, ИНН пользователя',
    isEnabledSearch: true,
    width: 180,
  },
  {
    id: PaymentAccrualsColumnsKeys.NAME,
    label: 'Пользователь',
    isEnabledSearch: true,
    width: 150,
  },
  {
    id: PaymentAccrualsColumnsKeys.STATUS,
    label: 'Статус',
    isEnabledFilter: true,
    width: 110,
    filter: 'filter',
  },
  {
    id: PaymentAccrualsColumnsKeys.TRADE_ID,
    label: 'ID торгов',
    isEnabledSearch: true,
    width: 130,
  },
  {
    id: PaymentAccrualsColumnsKeys.AMOUNT,
    label: 'Сумма операции (₽)',
    isEnabledFilter: true,
    width: 150,
    filter: 'filter',
  },
  {
    id: PaymentAccrualsColumnsKeys.APPROVAL_DATE,
    label: 'Дата операции',
    isEnabledFilter: true,
    width: 120,
    filter: 'filter',
  },
  {
    id: PaymentAccrualsColumnsKeys.PAYMENT_ORDER_ID,
    label: '№ п/п',
    isEnabledSearch: true,
    width: 110,
  },
  {
    id: PaymentAccrualsColumnsKeys.OPERATOR,
    label: 'Оператор',
    isEnabledFilter: true,
    width: 120,
  },
  {
    id: PaymentAccrualsColumnsKeys.META,
    label: 'Метаданные',
    isEnabledFilter: true,
    width: 140,
    filter: 'filter',
  },
  {
    id: PaymentAccrualsColumnsKeys.COMMENT,
    label: 'Комментарии',
    width: 150,
  },
];

export const paymentAccrualsFilterKey = {
  id: ['id'],
  type: ['type'],
  user_id: ['user_id', 'user_inn'],
  name_user_or_company: ['name_user_or_company'],
  user_status: ['user_status'],
  trade_id: ['trade_id'],
  amount: ['from_amount', 'to_amount'],
  approval_date: ['from_approval_date', 'to_approval_date'],
  payment_order_id: ['payment_order_id'],
  user_created: ['user_created'],
  meta: ['is_manual', 'is_reverse'],
  comment: ['comment'],
};

export const fileLogAccrualsColumns: Column<PaymentRegistryRequestConfig>[] = [
  {
    id: 'transaction_id',
    label: 'ID операции',
    width: 110,
  },
  {
    id: 'created_at',
    label: 'Дата операции',
    width: 110,
    filter: 'filter',
  },
  {
    id: 'file_name',
    label: 'Название закачанного файла',
    width: 170,
  },
  {
    id: 'user_full_name',
    label: 'ФИО оператора',
    width: 190,
  },
];

export const fileLogAccrualsFilterKey = {
  transaction_id: ['transaction_id'],
  created_at: ['from_created_at', 'to_created_at'],
  file_name: ['file_name'],
  user_full_name: ['user_full_name'],
};

export enum UploadPaymentOpenEnum {
  FILE_MANAGER = 'FileManager',
  FILE_RESULT = 'FileResult',
  SEND_TRANSACTION = 'SendTransaction',
  SEND_TRANSACTION_FORCE = 'SendTransactionForce',
}

export enum UploadPaymentTypeFileEnum {
  CHECKING_ACCOUNT = 'xls_checking_account',
  ACQUIRING = 'xls_acquiring',
}

export interface UploadFileType {
  title: string;
  value: UploadPaymentTypeFileEnum;
}

export const UPLOAD_FILE_TYPE: UploadFileType[] = [
  { title: 'Excel-файл с оплатами через расчетный счет', value: UploadPaymentTypeFileEnum.CHECKING_ACCOUNT },
  { title: 'Excel-файл с оплатами через эквайринг', value: UploadPaymentTypeFileEnum.ACQUIRING },
];

export enum UploadPaymentTypeEnum {
  CHECKING_ACCOUNT = 'checking_account',
  ACQUIRING = 'acquiring',
}

export interface UploadPaymentType {
  title: string;
  value: UploadPaymentTypeEnum;
}

export const UPLOAD_PAYMENT_TYPE: UploadPaymentType[] = [
  { title: 'Рачетный счет', value: UploadPaymentTypeEnum.CHECKING_ACCOUNT },
  { title: 'Эквайринг', value: UploadPaymentTypeEnum.ACQUIRING },
];

export const paymentsFromFileColumns: Column<PaymentRegistryRequestConfig>[] = [
  {
    id: 'checkbox',
    label: '',
    width: 50,
    checkbox: true,
    error: false,
  },
  {
    id: 'approval_date',
    label: 'Дата операции',
    width: 110,
    error: null,
  },
  {
    id: 'payment_type',
    label: 'Тип транзакции',
    width: 170,
    error: null,
  },
  {
    id: 'itn',
    label: '№ ПП',
    width: 170,
    error: null,
  },
  {
    id: 'itn',
    label: 'ИНН',
    width: 190,
    error: null,
  },
  {
    id: 'amount',
    label: 'Сумма операции',
    width: 190,
    error: null,
  },
  {
    id: 'comment',
    label: 'Комментарий',
    width: 190,
    error: null,
  },
  {
    id: 'error',
    label: 'Ошибка',
    width: 250,
    error: true,
  },
];

export const META_STATUSES: MultiSelectListItem[] = [
  {
    id: StatusesEnum.ACTIVE,
    name: 'Актив',
    iconSvg: 'circle',
    classes: ['status-green'],
    checked: false,
  },
  {
    id: StatusesEnum.IN_ARCHIVE,
    name: 'Архив',
    iconSvg: 'circle',
    classes: ['status-red'],
    checked: false,
  },
];
