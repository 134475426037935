import { ChatSections } from '@app/chat/models/chat.model';

export enum ChatSectionsEnum {
  HOLDING = 'holding',
  TRADE = 'trade',
  ADMIN = 'admin',
  TECH = 'tech',
  DUTY_TSO = 'tso',
}

export const CHAT_SECTIONS: ChatSections = {
  corp: {
    name: ChatSectionsEnum.HOLDING,
    icon: ['sitemap'],
    isSelected: true,
    title: 'Корпоративный раздел',
    counter: 0,
  },
  trade: {
    name: ChatSectionsEnum.TRADE,
    icon: ['gavel'],
    isSelected: false,
    title: 'Торговый раздел',
    counter: 0,
  },
  admin: {
    name: ChatSectionsEnum.ADMIN,
    icon: ['user-group'],
    isSelected: false,
    title: 'Административный раздел',
    counter: 0,
  },
  tech: {
    name: ChatSectionsEnum.TECH,
    icon: ['tools'],
    isSelected: false,
    title: 'Технический раздел',
    counter: 0,
  },
  otp: {
    name: ChatSectionsEnum.DUTY_TSO,
    icon: ['tools'],
    isSelected: false,
    title: 'Раздел для деж. ОТП',
    counter: 0,
  },
};

export enum TradeRoomsTypes {
  CUSTOMER_OTHER = 'customer_other',
  PROVIDER_OTHER = 'provider_other',
  PROVIDER_CUSTOMER = 'provider_customer',
  CUSTOMER = 'customer',
  PROVIDER = 'provider',
}

export enum GroupSortingNames {
  LAST_MESSAGE_DATE = 'last_message_date',
  CREATED_DATE = 'created_date',
  TITLE = 'title',
  OWNER = 'owner',
}

export enum TechTypeEnum {
  MY_TECH = 'my_tech',
  MY_USERS = 'my_users',
  ALL_USERS = 'all_users',
}
