import { ElementRef } from '@angular/core';

export function getScrollOffset(container: ElementRef<HTMLElement>, tab: ElementRef<HTMLElement>) {
  const containerEl = container.nativeElement;
  const currentTabEl = tab.nativeElement;

  // Позиция вкладки относительно контейнера
  const tabLeft = currentTabEl.offsetLeft;
  const tabRight = tabLeft + currentTabEl.offsetWidth;

  /*
   * Видимая область контейнера (у контейнера должно быть позиционирование,
   * иначе позиция вкладки, будет рассчитана от ближайшего предка с позиционированием.
   * */
  const containerLeft = containerEl.scrollLeft;
  const containerRight = containerLeft + containerEl.clientWidth;

  // если вкладка в видимой области родителя, оставляем как есть.
  let scrollOffset = containerLeft;

  /*
   * Если вкладка выходит за левую или правую границу контейнера,
   * сдвиг оставляя видимую часть следующей вкладки ~20px.
   * scrollleft - не может быть отрицательным, или больше максимума,
   * и автоматически устанавливается в 0 или максимум.
   * Дополнительные вычисления не требуются. (Источник - MDN).
   * */
  if (tabLeft < containerLeft) {
    scrollOffset = tabLeft - 20;
  } else if (tabRight > containerRight) {
    scrollOffset = tabRight - containerEl.clientWidth + 20;
  }

  return scrollOffset;
}
