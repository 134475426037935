<div *ngIf="title.length > 0" class="multiple-search-text-all">
  <input class="cbx-input" id="all" name="all" type="checkbox" [(ngModel)]="allChecked" (click)="changeAll()" />
  <label
    class="cbx-label"
    [class.cbx-label-interminate]="partiallySelected"
    for="all"
    appTestLocator="multiple-livesearch-select__select-all"
  >
    <span class="cbx-label-text">{{ title }}</span>
  </label>
</div>

<ul class="list-unstyled mb-3">
  <li *ngFor="let item of list" [ngClass]="{ 'list-li-wrapper': item.icon }">
    <a>
      <input
        [id]="item.id"
        class="cbx-input"
        [(ngModel)]="item.checked"
        type="checkbox"
        (ngModelChange)="changeModel(item)"
      />
      <label class="cbx-label" [for]="item.id" appTestLocator="multiselect-filter__item-{{ item.id }}">
        <ng-container *ngIf="item.iconSvg">
          <app-svg-icon
            class="ml-2"
            [name]="item.iconSvg"
            [ngClass]="item.classes"
            height="14"
            width="14"
          ></app-svg-icon>
        </ng-container>
        <span class="cbx-label-text">{{ item.name }}</span>
      </label>
    </a>
  </li>
</ul>
<div *ngIf="withCloseBtn; else withoutCloseBtn" class="buttons-action-wrapper">
  <button class="btn btn-dc-outline btn-sm" (click)="cancel()" appTestLocator="multiselect-filter__cancel">
    Отменить
  </button>
  <button
    class="btn btn-dc-primary btn-sm"
    [disabled]="isApplyDisable"
    (click)="apply()"
    appTestLocator="multiselect-filter__confirm"
  >
    Применить
  </button>
</div>

<ng-template #withoutCloseBtn>
  <div class="buttons-action-wrapper justify-content-end">
    <button
      class="btn btn-dc-primary"
      [disabled]="isApplyDisable"
      (click)="apply()"
      appTestLocator="multiselect-filter__confirm"
    >
      Применить
    </button>
  </div>
</ng-template>
