<div class="modal-header">
  <div class="modal-title chat-name-title">{{ title }}</div>
</div>
<form [formGroup]="chatGroupNameForm">
  <dc-form-field-wrapper [style]="'material'" [vertically]="false" class="chat-name-form-wrapper">
    <dc-form-label>Название</dc-form-label>
    <dc-form-input isShowLength="true" maxLength="50">
      <input
        formInput
        formControlName="name"
        [placeholder]="placeholder"
        type="text"
        [maxLength]="50"
        class="number-input"
      />
    </dc-form-input>
  </dc-form-field-wrapper>
</form>

<div class="d-flex justify-content-between">
  <button class="btn btn-dc-outline" (click)="closeModal()">Отменить</button>
  <button
    [disabled]="!chatGroupNameForm.valid || !chatGroupNameForm.get('name').value.length"
    class="btn btn-dc-primary"
    (click)="saveChanges()"
  >
    Сохранить
  </button>
</div>
