import { takeUntil } from 'rxjs/operators';
import { ChatService } from '@app/chat/services/chat.service';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ChatRoom } from '@app/chat/models/chat.model';
import { getScrollOffset } from '@app/chat/helpers';
import { DestroyService } from '@app/services/destroy.service';

@Component({
  selector: 'app-chat-tabs',
  templateUrl: './chat-tabs.component.html',
  styleUrls: ['./chat-tabs.component.scss'],
  providers: [DestroyService],
})
export class ChatTabsComponent implements OnInit, AfterViewInit {
  @ViewChild('scrollMe', { static: true }) scrollMe: ElementRef;
  contactSelected: ChatRoom;
  tabs: ChatRoom[] = [];
  private isMouseDown = false;
  private startX = 0;
  private scrollLeft = 0;
  protected isDragging = false;

  constructor(
    private readonly chatService: ChatService,
    private readonly destroy$: DestroyService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.tabs = this.filterTabs(this.chatService.getTabs());
    this.chatService.tabsChanged.pipe(takeUntil(this.destroy$)).subscribe((tabs) => {
      this.tabs = this.filterTabs(tabs);
      this.cdr.detectChanges();
    });
    this.contactSelected = this.chatService.getContactSelected();
    this.chatService.contactSelectedChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe((contactSelected) => (this.contactSelected = contactSelected));
  }

  filterTabs(tabs: ChatRoom[]) {
    return tabs.filter((tab) => !this.chatService.isTradeGroup(tab));
  }

  ngAfterViewInit() {
    this.chatService.scrollToTabChanged.pipe(takeUntil(this.destroy$)).subscribe((elem) => {
      setTimeout(() => {
        this.scrollMe.nativeElement.scrollTo({
          left: getScrollOffset(this.scrollMe, elem),
          behavior: 'smooth',
        });
      }, 0);
    });
  }

  scroll(size: number) {
    this.scrollMe.nativeElement.scrollLeft += size;
  }

  canScrollR(): boolean {
    return (
      this.scrollMe.nativeElement.scrollLeft <
      this.scrollMe.nativeElement.scrollWidth - this.scrollMe.nativeElement.clientWidth
    );
  }

  canScrollL(): boolean {
    return this.scrollMe.nativeElement.scrollLeft > 0;
  }

  onMouseDown($event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    this.isMouseDown = true;
    this.isDragging = false;
    this.startX = $event.pageX - this.scrollMe.nativeElement.offsetLeft;
    this.scrollLeft = this.scrollMe.nativeElement.scrollLeft;
  }

  onMouseMove($event: MouseEvent) {
    if (!this.isMouseDown) return;
    $event.stopPropagation();
    $event.preventDefault();
    const x = $event.pageX - this.scrollMe.nativeElement.offsetLeft;
    const dragOffset = x - this.startX;

    if (Math.abs(dragOffset) >= 10) {
      this.isDragging = true;
    }

    this.scrollMe.nativeElement.scrollLeft = this.scrollLeft - dragOffset;
  }

  onMouseOut($event: MouseEvent) {
    $event.stopPropagation();
    this.isMouseDown = false;
  }
}
