<div class="chat-message-editor">
  <button type="button" class="icon-button icon-button_mb" [disabled]="!contactSelected.id" (click)="openFileManager()">
    <app-svg-icon name="paperclip" width="16" height="16"></app-svg-icon>
  </button>
  <button type="button" class="icon-button icon-button_mb">
    <app-svg-icon name="wall-clock" width="16" height="16"></app-svg-icon>
  </button>

  <div class="message-wrapper">
    <div class="quote" *ngIf="quotedMessage">
      <div class="quote__message">
        <span class="quote__message--limited">{{ quotedMessage }}</span>
      </div>

      <button type="button" class="icon-button" (click)="removeQuotedMessage()">
        <app-svg-icon name="trash" width="16" height="16"></app-svg-icon>
      </button>
    </div>
    <form [formGroup]="messageForm">
      <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="false" [required]="false">
        <textarea
          #messageInput
          (keydown.enter)="$event.preventDefault(); onMessageFormSubmit()"
          (input)="changeTextareaHeight()"
          formControlName="message"
          type="text"
          rows="1"
          maxlength="10000"
          [placeholder]="placeholder"
        ></textarea>
      </dc-form-field-wrapper>
    </form>
  </div>

  <button
    type="button"
    class="icon-button icon-button_mb"
    [class.disabled]="!messageForm.controls.message.value || !contactSelected.id"
    (click)="onMessageFormSubmit()"
  >
    <app-svg-icon name="paper-plane-send" width="24" height="24"></app-svg-icon>
  </button>
</div>
<div class="mt-3 ml-5" *ngIf="files.length">
  <span class="blue-links d-inline-flex" *ngFor="let file of files">
    <span class="mr-1">{{ file.name }}</span>
    <a (click)="removeFile(file)" class="mr-3">
      <app-svg-icon name="times" width="14" height="14"></app-svg-icon>
    </a>
  </span>
</div>

<!--<app-modal-window [isShowed]="isFileManagerOpened" (closeEvent)="closeFileManager()" customClass="file-manager-modal">-->
<!--  <app-file-manager-->
<!--    *ngIf="isFileManagerOpened"-->
<!--    (onExportSelected)="handleSelectedExport($event)"-->
<!--    [layout]="tradesLayouts.EXPORT"-->
<!--  ></app-file-manager>-->
<!--</app-modal-window>-->
