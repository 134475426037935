import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ROLES, RolesEnum } from '@app/shared/constants/roles.constants';
import { AuthService } from '@app/shared/services/auth.service';
import { ChatService } from '@app/chat/services/chat.service';
import { SocketDataService } from '@app/services/socket-data.service';
import { ChatRoom, ChatSection, ChatUserTreeWithNode } from '@app/chat/models/chat.model';
import { ChatSectionsEnum } from '@app/chat/constants/chat-sections.constants';
import { User } from '@app/shared/models/user.model';
import { ITreeOptions, TREE_ACTIONS, TreeComponent, TreeModel, TreeNode } from '@circlon/angular-tree-component';
import { StatusesEnum } from '@app/shared/constants/statuses.constants';
import { TreeHelper } from '@app/shared/helpers/tree.helper';
import { DestroyService } from '@app/services/destroy.service';
import { cloneDeep } from 'lodash';
import { limitMaxTreeLevel } from '@app/chat/helpers';
import _ from 'lodash';

@Component({
  selector: 'app-chat-group-or-theme-edit',
  templateUrl: './chat-group-or-theme-edit.component.html',
  styleUrls: ['./chat-group-or-theme-edit.component.scss'],
})
export class ChatGroupOrThemeEditComponent implements OnInit {
  contacts: ChatUserTreeWithNode[] = [];
  groupContacts = [];
  roles = ROLES;
  editingObject: ChatRoom;
  chatSectionSelected: ChatSection = {} as ChatSection;

  //Для правой стороны предусмотреть отдельный фильтр не contactsOnlyFilter
  contactsOnlyFilter;

  chatSectionsEnum = ChatSectionsEnum;
  userId: number;
  rolesEnum = RolesEnum;

  userTreeList: ChatUserTreeWithNode[] = [];

  customTemplateStringOptions: ITreeOptions = {};
  statuses = StatusesEnum;

  contactsValue: string = '';
  membersValue = '';

  selectedContacts: ChatUserTreeWithNode[] = [];
  contactsPartiallySelected: boolean;
  allContactsCheckbox: boolean;

  selectedMembers: ChatUserTreeWithNode[] = [];
  allMembersCheckbox: boolean;

  leftUserList: User[] | ChatUserTreeWithNode[];
  haveSelectableContacts: boolean = true;
  isChanged = true;

  private currentUser: ChatUserTreeWithNode;
  private isSaved = true;

  @ViewChild('tree') private tree: TreeComponent;

  constructor(
    private authService: AuthService,
    private chatService: ChatService,
    private chatDataService: SocketDataService,
    private destroy: DestroyService
  ) {}

  get isGroup() {
    return !this.editingObject.group_id;
  }

  get disableRightAllCheckBox() {
    return (
      this.groupContacts.length <= 1 ||
      (this.groupContacts.length === 2 &&
        this.groupContacts.filter(
          (contact) => contact.type === RolesEnum.SUPERUSER || contact.id === this.editingObject.owner_id
        ).length === 2) ||
      (this.groupContacts.length === 3 &&
        this.groupContacts.filter(
          (contact) =>
            contact.type === RolesEnum.SUPERUSER ||
            contact.id === this.userId ||
            contact.id === this.editingObject.owner_id
        ).length === 3)
    );
  }

  get membersPartiallySelected() {
    const members = this.groupContacts.filter(
      (contact) => contact.type !== RolesEnum.SUPERUSER && contact.id !== this.editingObject.owner_id
    );
    return members.length && members.some((item) => item.selected) && !members.every((item) => item.selected);
  }

  get isHoldingGroup() {
    return this.chatSectionSelected.name === this.chatSectionsEnum.HOLDING && !this.editingObject.group_id;
  }

  ngOnInit() {
    this.chatSectionSelected = this.chatService.getChatSectionSelected();
    this.editingObject = this.chatService.getEditingGroupOrThemeObject();
    this.groupContacts = cloneDeep(this.editingObject.userItems);
    this.contactsOnlyFilter = this.chatService.getContactsOnlyFilter();

    this.userId = +this.authService.user_id;

    this.fillUserLists(this.chatService.getContacts());

    this.authService.userStream.pipe(takeUntil(this.destroy)).subscribe((user) => {
      this.currentUser = user;
    });

    this.chatService.contactsChanged.pipe(takeUntil(this.destroy)).subscribe((contacts) => {
      this.fillUserLists(contacts);
      // this.contacts = contacts;
      this.fillLeftUserList();
    });

    this.chatService.userTreeChanged.pipe(takeUntil(this.destroy)).subscribe((r) => {
      this.fillUseTreeList();
    });

    this.chatService.contactsOnlyFilterChanged
      .pipe(takeUntil(this.destroy))
      .subscribe((contactsOnlyFilter) => (this.contactsOnlyFilter = contactsOnlyFilter));

    this.fillUseTreeList();
    this.fillLeftUserList();
    this.updateAllMembersCheckbox();
    this.updateAllContactsCheckbox();

    if (!this.groupContacts.length) {
      this.groupContacts = [this.currentUser];
    } else {
      this.groupContacts.forEach((contact) => {
        contact.selected = false;
        contact.disabled = false;
      });
    }
  }

  ngAfterViewInit(): void {
    this.tree?.treeModel.expandAll();
  }

  private fillLeftUserList() {
    if (this.isHoldingGroup) {
      return;
    }
    //Добавить это к проверке this.currentUser.type === RolesEnum.SUPERUSER
    if (this.chatSectionSelected.name === this.chatSectionsEnum.ADMIN && !this.editingObject.group_id) {
      this.leftUserList = [this.currentUser, ...this.contacts];
    }

    if (this.editingObject.group_id) {
      this.leftUserList = [...this.editingObject.group.userItems];
    }

    this.checkLeftUserList();
  }

  private fillUseTreeList() {
    const userContactTree = this.chatService.getUserTree();
    if (!userContactTree || !this.isHoldingGroup) {
      return;
    }
    const cloneUserTree = cloneDeep(userContactTree);
    // const MAX_LEVEL = 10;
    // limitMaxTreeLevel(cloneUserTree, MAX_LEVEL);

    this.userTreeList = [cloneUserTree, ...cloneUserTree.children];

    this.userTreeList[0].children = [];
    this.userTreeList.forEach((item) => {
      this.checkUserTreeList(item);
    });
  }

  private checkUserTreeList(node: ChatUserTreeWithNode, level: number = 0): void {
    if (!this.isHoldingGroup) {
      return;
    }
    const groupIds = this.groupContacts.map((contact) => +contact.id);
    const selectedIds = this.selectedContacts.map((contact) => +contact.id);
    if (groupIds?.includes(+node.id)) {
      node.disabled = true;
    } else {
      node.disabled = false;
    }
    if (selectedIds.includes(+node.id)) {
      node.selected = true;
    } else {
      node.selected = false;
    }
    if (node.children) {
      for (const child of node.children) {
        this.checkUserTreeList(child, level + 1);
      }
    }
  }

  private checkLeftUserList() {
    if (this.isHoldingGroup) {
      return;
    }

    const groupIds = this.groupContacts.map((contact) => +contact.id);
    const selectedIds = this.selectedContacts.map((contact) => +contact.id);
    this.leftUserList.forEach((item) => {
      if (groupIds.includes(+item.id)) {
        item.disabled = true;
      } else {
        item.disabled = false;
      }

      if (selectedIds.includes(+item.id)) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
  }

  private fillUserLists(contacts: User[]) {
    this.contacts = [];

    Object.keys(contacts).forEach((room_id) => {
      contacts[room_id].selected = false;

      if (!this.editingObject.group) {
        this.contacts.push(contacts[room_id]);
        return;
      }
    });
  }

  expandItem(tree: TreeModel, node: TreeNode, $event: any): void {
    TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
  }

  edit() {
    // Позже добавить кейсы для торгового и тех раздела.
    const users: number[] = this.groupContacts.map((contact) => contact.id);
    if (this.editingObject.group_id) {
      switch (this.chatSectionSelected.name) {
        case ChatSectionsEnum.ADMIN:
        case ChatSectionsEnum.HOLDING:
          this.chatDataService.updateTopic({
            section: this.chatSectionSelected.name,
            id: this.editingObject.id,
            users,
          });
          break;

        // case ChatSectionsEnum.TRADE:
        //   this.chatDataService.updateTradeTopic({
        //     title: event,
        //     trade_id: this.group.trade_id,
        //     users: users
        //   })
        // break;
      }
    } else {
      switch (this.chatSectionSelected.name) {
        case ChatSectionsEnum.ADMIN:
        case ChatSectionsEnum.HOLDING:
          this.chatDataService.updateGroup({
            section: this.chatSectionSelected.name,
            group_id: this.editingObject.id,
            owner_id: this.editingObject.owner_id,
            users,
          });
          break;
      }
    }
    this.chatService.resetEditingGroupOrThemeObject();
    this.chatService.isEditingGroupOrThemeChanged.next(false);
    this.isSaved = true;
  }

  changeOwner(event: ChatUserTreeWithNode) {
    if (this.chatSectionSelected.name === this.chatSectionsEnum.TRADE) {
      return;
    }
    this.editingObject.owner_id = event.id;
    event.selected = false;
    this.selectMember(event);
    this.isSaved = false;
    this.isChanged = false;
  }

  onCancelGroupEdit() {
    this.chatService.resetEditingGroupOrThemeObject();
    this.chatService.isEditingGroupOrThemeChanged.next(false);
    this.chatService.toggleContactsOnlyFilter(false);
    this.isSaved = true;
  }

  onContactClick(contact: ChatUserTreeWithNode) {
    if (
      (this.editingObject.id &&
        this.editingObject.owner_id !== this.userId &&
        this.isSaved &&
        this.currentUser.type !== RolesEnum.SUPERUSER) ||
      contact.id === this.userId ||
      this.groupContacts.some((el) => el.id === contact.id) ||
      (this.chatSectionSelected.name === this.chatSectionsEnum.TRADE && !this.editingObject.group)
    ) {
      return;
    }
    contact.disabled = false;
    contact.selected = false;
    this.selectedContacts = this.selectedContacts.filter((item) => item.id !== contact.id);

    this.groupContacts.push({ ...contact });
    this.userTreeList.forEach((item) => {
      this.checkUserTreeList(item);
    });
    this.isChanged = false;
    this.checkLeftUserList();
    this.updateAllContactsCheckbox();
  }

  onGroupContactClick(contact: User) {
    if (
      (this.editingObject.id &&
        this.editingObject.owner_id !== this.userId &&
        this.isSaved &&
        this.currentUser.type !== RolesEnum.SUPERUSER) ||
      contact.id === this.userId ||
      contact.id === this.editingObject.owner_id ||
      contact.type === RolesEnum.SUPERUSER ||
      (this.chatSectionSelected.name === this.chatSectionsEnum.TRADE && !this.editingObject.group)
    ) {
      return;
    }

    this.groupContacts = this.groupContacts.filter((item) => contact.id !== item.id);
    this.selectedMembers = this.selectedMembers.filter((item) => item.id !== contact.id);
    this.isChanged = false;
    this.userTreeList.forEach((item) => {
      this.checkUserTreeList(item);
    });
    this.checkLeftUserList();
    this.updateAllContactsCheckbox();
    this.updateAllMembersCheckbox();
  }

  onFilterToggle() {
    this.chatService.toggleContactsOnlyFilter(true);
  }

  onFilterEdit($event: InputEvent) {
    if (!this.tree) {
      return;
    }

    const searchKeys = ['second_name', 'first_name', 'patronymic'];
    $event.stopPropagation();

    // Для корпоративного раздела чата здесь дописать условия фильтрациии после реализации фильтров
    // и вызывать этот метод приприменении фильтров и инициализации компоненты
    this.tree.treeModel.filterNodes((node: { data: ChatUserTreeWithNode }) => {
      if (!this.contactsValue) {
        node.data.searchedInactive = false;
        return true;
      }
      const value = this.contactsValue.toLowerCase();
      node.data.searchedInactive = true;
      return searchKeys.some((a) => {
        if (node.data[a] && node.data[a]?.toLowerCase().startsWith(value)) {
          node.data.searchedInactive = false;
          return true;
        }
      });
    });
  }

  onClearFilter() {
    this.chatService.toggleContactsOnlyFilter(false);
    this.chatService.setContactsOnlyFilter(false);
  }

  moveToGroup() {
    this.selectedContacts.forEach((item) => (item.selected = false));
    this.groupContacts.push(...this.selectedContacts);
    this.selectedContacts = [];
    this.allContactsCheckbox = false;
    this.userTreeList.forEach((item) => {
      this.checkUserTreeList(item);
    });
    this.isChanged = false;
    this.checkLeftUserList();
    this.updateAllContactsCheckbox();
  }

  moveFromGroup() {
    this.groupContacts.forEach((contact) => (contact.selected = false));
    this.groupContacts = this.groupContacts.filter(
      (contact) => !this.selectedMembers.some((member) => member.id === contact.id)
    );
    this.isChanged = false;
    this.selectedMembers = [];

    this.userTreeList.forEach((item) => {
      this.checkUserTreeList(item);
    });
    this.checkLeftUserList();
    this.updateAllMembersCheckbox();
    this.updateAllContactsCheckbox();
  }

  selectMember(event: ChatUserTreeWithNode) {
    if (event.selected) {
      this.selectedMembers.push({ ...event });
    } else {
      this.selectedMembers = this.selectedMembers.filter((item) => item.id !== event.id);
    }
    this.updateAllMembersCheckbox();
  }

  selectContact(event: ChatUserTreeWithNode) {
    if (event.selected) {
      this.selectedContacts.push({ ...event });
    } else {
      this.selectedContacts = this.selectedContacts.filter((item) => item.id !== event.id);
    }
    this.updateAllContactsCheckbox();
  }

  selectAllMembers(event: boolean) {
    this.groupContacts.forEach((item) => {
      if (
        !item.disabled &&
        item.type !== RolesEnum.SUPERUSER &&
        item.id !== this.editingObject.owner_id &&
        this.userId !== item.id
      ) {
        item.selected = event;
        if (event) {
          this.selectedMembers.push({ ...item });
          this.selectedMembers = _.uniqBy(this.selectedMembers, 'id');
        } else {
          this.selectedMembers = [];
        }
      }
    });
  }

  selectAllContacts(event: boolean) {
    this.userTreeList.forEach((item) => {
      this.selectAllContactsInTree(event, item);
    });
    this.selectAllContactsInList(event);
    // Вызываем его для кареток
    this.updateAllContactsCheckbox();
  }

  private selectAllContactsInTree(isConactSelected: boolean, node: ChatUserTreeWithNode, level: number = 0) {
    if (!this.isHoldingGroup) {
      return;
    }
    if (!node.disabled) {
      node.selected = isConactSelected;
      if (isConactSelected) {
        this.selectedContacts.push({ ...node });
        this.selectedContacts = _.uniqBy(this.selectedContacts, 'id');
      } else {
        this.selectedContacts = [];
      }
    }
    if (node.children) {
      for (const child of node.children) {
        this.selectAllContactsInTree(isConactSelected, child, level + 1);
      }
    }
  }

  private selectAllContactsInList(isConactSelected: boolean) {
    if (this.isHoldingGroup) {
      return;
    }

    this.leftUserList.forEach((item) => {
      if (!item.disabled) {
        item.selected = isConactSelected;
        if (isConactSelected) {
          this.selectedContacts.push({ ...item });
          this.selectedContacts = _.uniqBy(this.selectedContacts, 'id');
        } else {
          this.selectedContacts = [];
        }
      }
    });
  }

  private updateAllMembersCheckbox() {
    const members = this.groupContacts.filter(
      (contact) => contact.type !== RolesEnum.SUPERUSER && contact.id !== this.editingObject.owner_id
    );
    this.allMembersCheckbox = members.length && members.every((item) => item.selected);
  }

  private updateAllContactsCheckbox() {
    let contacts: ChatUserTreeWithNode[];

    if (this.isHoldingGroup) {
      contacts = TreeHelper.treeToArray(this.userTreeList).filter((contact) => !contact.disabled);
    } else {
      contacts = (this.leftUserList as ChatUserTreeWithNode[]).filter(
        (contact: ChatUserTreeWithNode) => !contact.disabled
      );
    }

    this.contactsPartiallySelected =
      contacts.length && contacts.some((item) => item.selected) && !contacts.every((item) => item.selected);
    this.allContactsCheckbox = contacts.length && contacts.every((item) => item.selected);
    this.haveSelectableContacts =
      contacts.filter((contact) => !contact.disabled && contact.status !== this.statuses.IN_ARCHIVE).length > 0;
  }
}
