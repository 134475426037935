import { BehaviorSubject, EMPTY, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import isEmpty from 'lodash-es/isEmpty';
import { environment } from 'environments/environment';

import { User } from 'app/shared/models/user.model';
import { TradeCustomer } from '../../+trades/models/customers.model';
import { AccessRights } from '../../+trades/constants/trades.constants';
import { AccessRight } from '../../+trades/types/trades.types';
import { TranslateService } from '@ngx-translate/core';
import { UserTypes } from '@app/shared/types/user.types';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { UserStatusesTypes } from '@app/shared/types/user-statuses.types';
import { LocalStorageService } from '@app/services/local-storage.service';

function errorHandler(error) {
  return observableThrowError(error);
}

const cookieOptions = { path: '/' };
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loggedIn = false;
  private _user_id: string;
  private _user_type: UserTypes;

  private _headers = new HttpHeaders();
  private _userStream: BehaviorSubject<any>;
  private _userStartPage: string;
  private _timezone_offset = 0;
  private _userStatus: BehaviorSubject<UserStatusesTypes | null>;

  get userStream() {
    /**
     * Если нужны данные о текущем пользователе - подписываемся(subscribe) на userStream
     * Если делаем запрос изменяющий текущего пользователе, не забываем вызывать authService.updateUser(),
     * и все подписчики получат обновленного User'а
     */
    return this._userStream.asObservable().pipe(filter((x) => x !== null));
  }

  get userStatus(): Observable<UserStatusesTypes | null> {
    return this._userStatus.asObservable().pipe(filter((x) => x !== null));
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService
  ) {
    this.loggedIn = !!this.cookieService.get('Authentication-Token');
    this._userStream = new BehaviorSubject(null);
    this._userStatus = new BehaviorSubject(null);
  }

  get user_id(): string {
    if (!this._user_id) {
      this._user_id = this.cookieService.get('user_id');
    }

    return this._user_id;
  }

  set user_id(id: string) {
    this._user_id = id;
  }

  get user_type(): UserTypes {
    if (!this._user_type) {
      this._user_type = this.cookieService.get('user_type') as UserTypes;
    }
    return this._user_type;
  }

  set user_type(type: UserTypes) {
    this._user_type = type;
  }

  // TODO: поправить логику установки Заголовков.
  // Сейчас заголовки, это ОДИН заголовок Authentication-Token
  get headers() {
    if (isEmpty(this._headers.keys())) {
      const token = this.getToken();
      if (token) {
        this._headers.append('Authentication-Token', token);
      }
    }
    return this._headers;
  }

  getToken() {
    return this.cookieService.get('Authentication-Token');
  }

  login(email: string, password: string, captcha: string): Observable<any> {
    let params = { email, password, 'g-recaptcha-response': captcha };
    if (captcha) {
      params = { ...params, 'g-recaptcha-response': captcha };
    }

    return this.http.post(`${environment.api_url}/auth/login`, params).pipe(
      switchMap((res: HttpResponse<any>) => {
        return this.enter(res);
      }),
      catchError((error) => {
        console.log(error);
        return errorHandler(error);
      })
    );
  }

  private getSelf(): Observable<User> {
    return this.http.get<User>(`${environment.api_url}/user/${this.user_id}?include=company.owner,parent`).pipe(
      map((u) => {
        const user = new User(u);
        this._timezone_offset = user.timezone_offset;
        this.nextUserData(user);
        return user;
      }),
      catchError((error) => {
        this.logout();
        return errorHandler(error);
      })
    );
  }

  private enter(res): Observable<User> {
    /**
     * Залогиниваем пользователя
     */
    this.cookieService.deleteAll();

    this.cookieService.set('Authentication-Token', res.auth_token, cookieOptions);
    this.cookieService.set('user_id', res.user_id, cookieOptions);
    this.user_id = res.user_id;

    this.loggedIn = true;
    this._userStartPage = res.start_page;
    return this.updateUser();
  }

  updateUser(): Observable<User> {
    /**
     * Получает новые данные о текущем Пользователе и отправляет их в userStream
     */
    if (!this.user_id) {
      return EMPTY;
    }
    return this.getSelf().pipe(
      map((user) => {
        const userType = user && user.type ? user.type : '';
        this.cookieService.set('user_type', userType, cookieOptions);
        this.user_type = userType as UserTypes;

        return user;
      })
    );
  }

  public nextUserData(user: User) {
    this._userStream.next(user);
    this._userStatus.next(user.status);
  }

  getTimezoneOffset(): number {
    return this._timezone_offset;
  }

  getMomentGtmTimezone(date) {
    return moment(date).add(this._timezone_offset, 'minutes');
  }

  register(email: string, password: string, type: string, captcha: string, tso_id: string): Observable<any> {
    const result = {};
    const params = {
      email,
      password,
      tso_id,
      type,
      'g-recaptcha-response': captcha,
    };
    return this.http.post(`${environment.api_url}/users`, params).pipe(
      mergeMap(() => this.activateUser(email)),
      map(() => result),
      catchError((error) => errorHandler(error))
    );
  }

  activateUser(email: string): Observable<any> {
    const callback = `${environment.callback_url}/activate`;
    return this.http
      .post(`${environment.api_url}/auth/activate_user`, { email, callback })
      .pipe(catchError((error) => errorHandler(error)));
  }

  activateConfirmUser(email: string, key: string): Observable<any> {
    return this.http
      .post(`${environment.api_url}/auth/activate_user/confirm`, {
        email,
        key,
      })
      .pipe(
        map((res) => this.enter(res)),
        catchError((error) => errorHandler(error))
      );
  }

  changePassword(current_password: string, new_password: string): Observable<any> {
    const data = {
      current_password,
      new_password,
    };
    return this.http.post(`${environment.api_url}/auth/change_password`, data);
  }

  changeEmail(current_password: string, new_email: string) {
    const callback = `${environment.callback_url}/confirm_change_email`;
    const data = {
      current_password,
      new_email,
      callback,
    };
    return this.http.post(`${environment.api_url}/auth/change_email`, data);
  }

  confirmChangeEmail(key: string): Observable<any> {
    const data = { key };
    return this.http.post(`${environment.api_url}/auth/change_email/confirm`, data);
  }

  resetPass(email: string): Observable<any> {
    const callback = `${environment.callback_url}/confirm_reset_pass`;
    return this.http
      .post(`${environment.api_url}/auth/reset_password`, { email, callback })
      .pipe(catchError((error) => errorHandler(error)));
  }

  resetPassConform(email: string, key: string, password: string): Observable<any> {
    return this.http
      .post(`${environment.api_url}/auth/reset_password/confirm`, {
        email,
        key,
        password,
      })
      .pipe(
        map((res) => this.enter(res)),
        catchError((error) => errorHandler(error))
      );
  }

  inviteUser(email: string): Observable<any> {
    const callback = `${environment.callback_url}/invite`;
    return this.http
      .post(`${environment.api_url}/auth/invite/send`, { email, callback })
      .pipe(catchError((error) => errorHandler(error)));
  }

  inviteConfirmUser(email: string, key: string): Observable<any> {
    return this.http.post(`${environment.api_url}/auth/invite/confirm`, { email, key }).pipe(
      map((res) => this.enter(res)),
      catchError((error) => errorHandler(error))
    );
  }

  /**
   * Повторная отправка письма с ссылкой для перехода в личный кабинет
   * @param userId
   */
  resendActivationUser(userId: number): Observable<any> {
    return this.http
      .post(`${environment.api_url}/auth/activate_user/${userId}/resend_activation_email`, {
        callback: environment.callback_url,
      })
      .pipe(catchError((error) => observableThrowError(error)));
  }

  setTimezoneOffset(timezone_offset: number): Observable<any> {
    this._timezone_offset = timezone_offset;
    return this.http
      .put(`${environment.api_url}/user/${this.user_id}`, { timezone_offset })
      .pipe(catchError((error) => errorHandler(error)));
  }

  setCustomIcons(icons: Object[]) {
    return this.http
      .put(`${environment.api_url}/user/${this.user_id}/icons`, icons)
      .pipe(catchError((error) => errorHandler(error)));
  }

  getCustomIcons(): Observable<Array<any>> {
    return this.http
      .get<Array<any>>(`${environment.api_url}/user/${this.user_id}/icons`)
      .pipe(catchError((error) => errorHandler(error)));
  }

  logout(): void {
    this.router.navigate([{ outlets: { popup: null } }]).then(() => {
      this.router.navigate(['signin']);
    });
    this.headers.delete('Authentication-Token');
    this.loggedIn = false;
    this._user_id = null;
    this.cookieService.deleteAll();
    this.localStorageService.clear();
  }

  isLoggedIn(): boolean {
    return !!this.loggedIn;
  }

  getCurrentUserAccess(customers: TradeCustomer[]): AccessRight {
    const filteredUsers = customers.filter((customer) => customer.user.id === +this.user_id);

    return filteredUsers.length ? filteredUsers[0].access_rights : AccessRights.VIEW;
    // return AccessRights.VIEW;
  }

  getErrorMessage(httpError: HttpErrorResponse, defaultError = 'Неизвестная ошибка!') {
    if (httpError && httpError.error && httpError.error.errors) {
      const result = Object.keys(httpError.error.errors)
        .map((key) => {
          return httpError.error.errors[key].toString();
        })
        .toString();

      return this.translateError(result);
    }

    if (httpError && httpError.error && httpError.error.message) {
      return this.translateError(httpError.error.message);
    }

    return defaultError;
  }

  getUserById(userId: number): Observable<User> {
    return this.http.get<User>(`${environment.api_url}/user/${userId}?include=company.owner,parent`).pipe(
      map((user) => {
        return new User(user);
      }),
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  private translateError(error: string) {
    if (!error) {
      return '';
    }

    if (error === 'Not a valid number.') {
      return this.translateService.instant('Неккоректное значение');
    }

    return this.translateService.instant(error);
  }
}
