<div
  (click)="onGroupSelect(group)"
  [routerLink]="group.room_id"
  [class.chat_active]="contactSelected?.room_id === group.room_id || groupSelected?.room_id === group.room_id"
  [style]="'--after-width:' + (listWidth + scrollPosition) + 'px'"
  class="group"
>
  <div class="d-flex align-items-center">
    <button
      (click)="$event.stopPropagation(); onToggleGroupExpanded()"
      class="icon-button chat-expansion__caret"
      [class.disabled]="!themes.length"
    >
      <app-svg-icon [name]="group.isGroupExpanded ? 'caret_up' : 'caret_down'" width="12" height="12"></app-svg-icon>
    </button>
    <div class="group-title">
      <div
        class="group-title-text"
        [class.text-overflow]="chatSectionSelected.name === chatSectionsEnum.ADMIN"
        [class.group-title--long]="chatSectionSelected.name !== chatSectionsEnum.ADMIN"
      >
        <span [class.text-overflow]="chatSectionSelected.name === chatSectionsEnum.ADMIN" [title]="group.title">{{
          group.title
        }}</span>
      </div>
    </div>
  </div>
  <div
    [style.marginLeft]="scrollPosition + 'px'"
    class="d-flex align-items-center group-right"
    [class.info__selected]="contactSelected?.room_id === group.room_id || groupSelected?.room_id === group.room_id"
  >
    <div class="d-flex align-items-center">
      <button
        class="icon-button cog-button"
        *ngIf="
          chatSectionSelected.name !== chatSectionsEnum.TECH && chatSectionSelected.name !== chatSectionsEnum.TRADE
        "
        (click)="$event.stopPropagation()"
        placement="left"
        [ngbPopover]="settingsContent"
        container="body"
        [class.disabled]="isOwner"
      >
        <app-svg-icon [name]="'cog'" width="15" height="15"></app-svg-icon>
      </button>
      <span
        class="chat-number"
        [style.visibility]="totalUnreadCounter ? 'visible' : 'hidden'"
        [class.chat-number__10]="totalUnreadCounter === '10+'"
      >
        {{ totalUnreadCounter }}
      </span>
    </div>
  </div>
</div>

<div>
  <div *ngFor="let theme of themes">
    <app-chat-theme
      *ngIf="group.isGroupExpanded"
      [theme]="theme"
      [group]="group"
      [userId]="userId"
      [userType]="user?.type"
      [contactSelected]="contactSelected"
    ></app-chat-theme>
  </div>
</div>

<app-modal-window [isShowed]="isReallyRemoving" (closeEvent)="isReallyRemoving = false" centered="true">
  <p class="mb-0">Вы действительно хотите удалить группу и входящие в нее темы?</p>
  <p class="mb-5 text-overflow">Восстановить удаленные данные будет невозможно!</p>
  <div class="d-flex justify-content-between">
    <button class="btn btn-dc-outline" (click)="isReallyRemoving = false">Нет, отменить</button>
    <button class="btn btn-dc-primary" (click)="onRemoveGroup()">Да, применить</button>
  </div>
</app-modal-window>

<app-modal-window [isShowed]="isShowRenameModal" (closeEvent)="isShowRenameModal = false" centered="true">
  <app-chat-group-theme-name-form
    *ngIf="isShowRenameModal"
    [isShowed]="isShowRenameModal"
    [name]="group.title"
    title="Переименовать группу"
    (cancelEdit)="closeModalName()"
    (saveName)="renameGroup($event)"
  ></app-chat-group-theme-name-form>
</app-modal-window>

<app-modal-window [isShowed]="isShowThemeModal" (closeEvent)="isShowThemeModal = false" centered="true">
  <app-chat-group-theme-name-form
    *ngIf="isShowThemeModal"
    [isShowed]="isShowThemeModal"
    title="Создать тему"
    placeholder="Введите название темы"
    (cancelEdit)="closeCreateThemeModal()"
    (saveName)="createTheme($event)"
  ></app-chat-group-theme-name-form>
</app-modal-window>

<ng-template #settingsContent>
  <button class="settings-buttons" (click)="$event.stopPropagation(); openRenameGroupModal()">
    <app-svg-icon [name]="'edit_list'" width="15" height="15"></app-svg-icon>
    Переименовать группу
  </button>
  <button class="settings-buttons" (click)="$event.stopPropagation(); onGroupEdit()">
    <app-svg-icon [name]="'users'" width="15" height="15"></app-svg-icon>
    Редактировать группу
  </button>
  <button class="settings-buttons" (click)="$event.stopPropagation(); onRemoveGroupAsk()">
    <app-svg-icon [name]="'delete'" width="15" height="15"></app-svg-icon>
    Удалить групппу
  </button>
  <button class="settings-buttons" (click)="$event.stopPropagation(); openCreateThemeModal()">
    <app-svg-icon [name]="'plus'" width="15" height="15"></app-svg-icon>
    Создать тему
  </button>
</ng-template>
