import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { ROLES, RolesEnum } from '@app/shared/constants/roles.constants';

import { ChatService } from '@app/chat/services/chat.service';
import { SocketDataService } from '@app/services/socket-data.service';
import { ChatRoom, ChatSection } from '@app/chat/models/chat.model';
import { Role } from '@app/shared/models/user.model';
import { ChatSectionsEnum, TradeRoomsTypes } from '@app/chat/constants/chat-sections.constants';

@Component({
  selector: 'app-chat-theme',
  templateUrl: './chat-theme.component.html',
  styleUrls: ['./chat-theme.component.scss'],
})
export class ChatThemeComponent implements OnInit, OnDestroy {
  @Input() userId: number | string;
  @Input() group: ChatRoom;
  @Input() theme: ChatRoom;
  @Input() contactSelected: ChatRoom;
  @Input() userType: RolesEnum;

  roles: Role[] = ROLES;
  chatSectionSelected: ChatSection;
  isReallyRemoving = false;
  chatSectionsEnum = ChatSectionsEnum;
  tradeRoomsTypes = TradeRoomsTypes;
  themeSelected: ChatRoom;
  scrollPosition: number = 0;

  isShowRenameModal = false;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private chatService: ChatService, private chatDataService: SocketDataService) {}

  ngOnInit() {
    this.chatSectionSelected = this.chatService.getChatSectionSelected();
    this.chatService.chatSectionSelectedChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((chatSectionSelected) => (this.chatSectionSelected = chatSectionSelected));

    this.chatService.themeSelectedChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((themeSelected) => {
      this.themeSelected = themeSelected;
    });

    this.chatService.scrollPosition.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.scrollPosition = res;
    });
  }

  onThemeSelect(theme: ChatRoom) {
    // console.log('SELECTED THEME', theme);
    this.chatService.setRoomSelected(theme);
    this.chatService.focusChanged.next(true);
  }

  onThemeUsersToggle() {
    this.chatService.toggleThemeOpen(this.theme, this.chatService.getChatSectionSelected().name);
  }

  // Актуализировать или удалить
  onThemeEdit() {
    this.chatService.setEditingGroupOrThemeObject({
      ...this.theme,
      group: this.group,
    });

    this.chatService.isEditingGroupOrThemeChanged.next(true);
  }

  openRenameThemeModal() {
    this.isShowRenameModal = true;
  }

  closeModalName() {
    this.isShowRenameModal = false;
  }

  renameTheme(title: string) {
    switch (this.chatSectionSelected.name) {
      case ChatSectionsEnum.ADMIN:
      case ChatSectionsEnum.HOLDING:
        this.chatDataService.updateTopic({
          section: this.chatSectionSelected.name,
          id: this.theme.id,
          title,
        });
        break;

      case ChatSectionsEnum.TRADE:
        this.chatDataService.updateTradeTopic({
          title,
          id: this.theme.id,
        });
        break;

      case ChatSectionsEnum.TECH:
        this.chatDataService.updateTechTopic({
          id: this.theme.id,
          title,
        });
        break;
    }

    this.isShowRenameModal = false;
  }

  onRemoveThemeAsk() {
    this.isReallyRemoving = true;
  }

  onRemoveTheme() {
    if (this.chatSectionSelected.name === this.chatSectionsEnum.TRADE) {
      this.chatDataService.removeTradeTopic(this.theme.id);
    } else {
      this.chatDataService.removeTopic({
        topic_id: this.theme.id,
        section: this.chatService.getChatSectionSelected(),
        room_id: this.theme.room_id,
      });
    }

    this.isReallyRemoving = false;
  }

  // Актализировать или удалить метод
  canEditTheme() {
    return (
      (this.userId === this.group.owner_id &&
        ((this.chatSectionSelected.name === this.chatSectionsEnum.TRADE &&
          (this.theme.type === this.tradeRoomsTypes.CUSTOMER_OTHER ||
            this.theme.type === this.tradeRoomsTypes.PROVIDER_OTHER)) ||
          this.chatSectionSelected.name === this.chatSectionsEnum.ADMIN ||
          this.chatSectionSelected.name === this.chatSectionsEnum.HOLDING)) ||
      (this.chatSectionSelected.name === this.chatSectionsEnum.TECH && this.userId === this.group.owner_id)
    );
  }

  get isOwner() {
    return !(this.userId === this.group.owner_id || this.userType === RolesEnum.SUPERUSER);
  }

  get totalUnreadCounter() {
    return this.chatService.totalUnreadCount([this.group]);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
