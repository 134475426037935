import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MultiSelectListItem } from '../../models/multi-select-list-item';
import { MultipleSearchSuggestion } from '@app/shared/models/multiple-search.model';

@Component({
  selector: 'app-multiselect-filter',
  templateUrl: './multiselect-filter.component.html',
  styleUrls: ['./multiselect-filter.component.scss'],
})
export class MultiselectFilterComponent implements OnChanges {
  allChecked: boolean;
  isPartialSelected: boolean;

  @Input() selected: string[] = [];
  @Input() selectOneOnly: boolean = false;
  @Input() title: string = '';
  @Input() withCloseBtn = true;

  @Output() submitEvent = new EventEmitter<{ [key: string]: any[] }>();
  @Output() cancelEvent = new EventEmitter<{}>();

  private _list: MultiSelectListItem[] = [];

  @Input() set list(list: MultiSelectListItem[]) {
    if (!list.length) {
      return;
    }

    this._list = list;
    this.isPartialSelected = this.isPartialSelectedCheck(list);
  }

  get list(): MultiSelectListItem[] {
    return this._list;
  }

  get partiallySelected(): boolean {
    return !this.allChecked && (this.isPartialSelected || this.isPartialSelectedCheck(this.list));
  }

  ngOnChanges() {
    if (!this.selected || (this.selected && !this.selected.length)) {
      this.list.forEach((status) => (status.checked = false));
      this.isPartialSelected = this.isPartialSelectedCheck(this.list);
    }
    if (this.selected && Array.isArray(this.selected) && this.selected.length) {
      this.list.forEach((status) => (status.checked = false));

      this.selected.forEach((statusId) => {
        this.list.forEach((status) => {
          if (status.id === statusId) {
            status.checked = status.id === statusId;
          }
        });
      });

      this.allChecked =
        this.list.length &&
        this.list.every((item) => {
          return item.checked;
        });
    }
  }

  get isApplyDisable(): boolean {
    return !this.list.some((item) => item.checked);
  }

  changeModel(event) {
    if (this.selectOneOnly && event.checked) {
      this.list.forEach((item) => {
        item.checked = item.id === event.id;
      });
    }

    this.isPartialSelected = this.isPartialSelectedCheck(this.list);
    this.allChecked = this.list.length && this.list.every((item) => item.checked);
  }

  apply() {
    const result = this.list.filter((status) => status.checked).map((status) => status.id);

    this.submitEvent.emit({ value: result });
  }

  cancel() {
    this.list = this.list.map((item) => ({
      ...item,
      checked: false,
    }));

    this.cancelEvent.emit();
  }

  changeAll() {
    this.list.forEach((item) => {
      item.checked = !this.allChecked;
    });
    this.isPartialSelected = this.isPartialSelectedCheck(this.list);
  }

  private isPartialSelectedCheck(list: MultiSelectListItem[]): boolean {
    return list.some((item) => item.checked) && !list.every((item) => item.checked);
  }
}
