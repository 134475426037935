import { debounceTime } from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SearchLocationService } from 'app/shared/services/search-location.service';
import { RequestsService } from 'app/shared/services/requests.service';
import { Location, Region, City } from 'app/shared/models/location.model';

@Component({
  selector: 'app-location',
  templateUrl: './search-location.component.html',
  styleUrls: ['./search-location.component.sass'],
  providers: [SearchLocationService],
})
export class SearchLocationComponent implements OnInit, OnChanges {
  @Input() element: string;
  @Input() isAddCityButtonVisible: boolean;
  @Input() disabled = false;
  _location: Location;
  get location(): Location {
    return this._location;
  }

  @Input('location')
  set location(value: Location) {
    this._location = value;
    if (value.isSelected) this.onSelectLocation(value);
  }

  @Output() locationChange = new EventEmitter();
  @ViewChild('add_location_modal') private modal: NgbModalRef;
  locations = [];
  newCity: string;
  newRegion: string;
  newCountry: string;
  newComment: string;
  searchText = '';
  isLoading = false;
  showAddLocation = false;

  private searchTerm = new Subject<string>();

  constructor(
    private searchLocationService: SearchLocationService,
    private requestsService: RequestsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) {
    // TODO   this.searchTerm.debounceTime(300).distinctUntilChanged().subscribe((searchTerm) => {
    //    distinctUntilChanged() - шлет запрос только если он отличается от предыдущего
    // в данном случае вызывает проблемы, т.к. при редактировании поля поиска локации удаляются для очистки списка.
    // а для использования этого метода, необходимо скрывать данные, а не очищать
    this.searchTerm.pipe(debounceTime(300)).subscribe((searchTerm) => {
      this.searchLocationService.searchLocation(searchTerm, this.element, this.location).subscribe(
        (response) => {
          this.isLoading = false;
          const emptyCity = new City('');
          const emptyRegion = new Region('');
          switch (this.element) {
            case 'city':
              response.forEach((city) => {
                this.locations.push(new Location({ city }));
              });
              break;
            case 'region':
              response.forEach((region) => {
                this.locations.push(new Location({ region }));
              });
              break;
            case 'country':
              response.forEach((country) => {
                this.locations.push(new Location({ country }));
              });
              break;
            default:
              response.forEach((location) => {
                this.locations.push(new Location(location));
              });
          }
        },
        (e) => {
          this.isLoading = false;
          // TODO do smth???
        }
      );
    });
  }

  ngOnInit() {
    this.isAddCityButtonVisible = this.isAddCityButtonVisible || false;
    if (this.location.isSelected) this.onSelectLocation(this.location);
  }

  ngOnChanges() {
    this.searchText = this.location[this.element || 'city'] ? this.location[this.element || 'city'].title || '' : '';
  }

  onKeyup(searchText: string) {
    this.locations = [];
    if (searchText.length >= 2) {
      this.isLoading = true;
      this.showAddLocation = true;
      this.searchTerm.next(searchText);
    }
    this.searchText = searchText;
    this.location.isSelected = false;
  }

  onSelectLocation(location: Location) {
    this._location = location;
    this.searchText = this.location[this.element || 'city'] ? this.location[this.element || 'city'].title || '' : '';
    this.location.isSelected = true;
    this.showAddLocation = false;
    this.locations = [];
    this.locationChange.emit(location);
  }

  addNewLocation() {
    this._location[this.element].title = this.searchText;
    delete this._location[this.element].id;
    this.onSelectLocation(this.location);
  }

  searchFocusOut() {
    /**
     * При потере фокуса, восстанавливам ранее выбранную локацию
     */
    this.onSelectLocation(this.location);
  }

  onAddLocationLinkClick() {
    this.modalService.open(this.modal);
  }
}
