<div
  class="contact-parent"
  *ngIf="contact"
  [class.chat_active]="contactSelected?.room_id === contact?.room_id"
  [style]="'--after-width:' + (listWidth + scrollPosition) + 'px'"
>
  <div class="d-flex align-items-center">
    <button
      *ngIf="userType !== rolesEnum.SUPERUSER"
      [style.visibility]="
        isTree && (contact.type === rolesEnum.ADMIN_OF_DIRECTION || contact.type === rolesEnum.ADMIN_OF_USER)
          ? 'visible'
          : 'hidden'
      "
      class="icon-button show-tree chat-expansion__caret"
      [disabled]="!contact?.children?.length"
      (click)="onContactsExpand()"
    >
      <app-svg-icon [name]="isExpanded ? 'caret_up' : 'caret_down'" width="12" height="12"></app-svg-icon>
    </button>
    <div
      *ngIf="contact.status !== 'in_archive' && contact.id"
      [style.paddingLeft]="userType === rolesEnum.SUPERUSER ? '17px' : '0px'"
      [routerLink]="contact?.room_id"
      [class.disabled]="!contact?.room_id"
      (click)="onContactSelect(contact)"
      class="contact"
      style="padding: 0"
    >
      <div class="d-flex align-items-center">
        <div class="contact-item id" *ngIf="chatSectionName && chatSectionName === ChatSectionsEnum.TECH">
          ID {{ contact.id }}
        </div>
        <div
          class="contact-item"
          [disableTooltip]="isContactTooltipDisabled(contact | initials, contact.type | titleRole)"
          ngbTooltip="{{ contact | initials }}, {{ contact.type | titleRole }}"
        >
          <span class="contact__name" [className]="!isTree ? 'short-name' : 'long-name'"
            >{{ contact | initials }}

            <ng-container *ngIf="contact.is_tso && !contact.is_duty_tso"> ОТП </ng-container>
            <ng-container *ngIf="contact.is_duty_tso"> Деж. ОТП </ng-container>
            <ng-container *ngIf="contact.type === rolesEnum.ADMIN_OF_DIRECTION"> АН </ng-container>
            <ng-container *ngIf="contact.type === rolesEnum.ADMIN_OF_USER"> АП </ng-container>
            <ng-container
              *ngIf="
                !contact.is_duty_tso &&
                !contact.is_tso &&
                contact.type !== rolesEnum.ADMIN_OF_DIRECTION &&
                contact.type !== rolesEnum.ADMIN_OF_USER
              "
            >
              , {{ contact.type | titleRole | lowercase }}
            </ng-container>
          </span>
        </div>
      </div>
      <div
        class="contact-info"
        [style.marginLeft]="scrollPosition - treeLevel * 10 + 'px'"
        [class.contact-info__selected]="contactSelected?.room_id === contact?.room_id"
      >
        <button class="icon-button" (click)="showCard(contact)">
          <app-svg-icon [width]="18" [height]="14" name="id-card"></app-svg-icon>
        </button>

        <button
          class="icon-button"
          (click)="showCompetence(contact)"
          [disabled]="contact.type === rolesEnum.SUPERUSER || contact.type === rolesEnum.ACCOUNTANT"
        >
          <app-svg-icon [width]="18" [height]="14" name="competence-card"></app-svg-icon>
        </button>

        <span
          [style.visibility]="contact.counter ? 'visible' : 'hidden'"
          class="chat-number"
          [class.chat-number__10]="contact.counter > 10"
        >
          {{ contact.counter <= 10 ? contact.counter : '10+' }}
        </span>
      </div>
    </div>
  </div>
  <div class="contact-children" *ngIf="isExpanded">
    <div *ngFor="let item of contact?.children; trackBy: identify">
      <app-chat-contact [isTree]="true" [contact]="item" [treeLevel]="treeLevel + 1"></app-chat-contact>
    </div>
  </div>
</div>
