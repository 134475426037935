<div
  (click)="onThemeSelect(theme)"
  [routerLink]="theme.room_id"
  [class.chat_active]="contactSelected.room_id === theme.room_id || themeSelected?.room_id === theme?.room_id"
  [style]="'--after-width:' + (310 + scrollPosition) + 'px'"
  class="theme d-flex"
>
  <div class="theme-title">
    <div
      class="theme-title-text"
      [class.text-overflow]="chatSectionSelected.name === chatSectionsEnum.ADMIN"
      [class.theme-title--long]="chatSectionSelected.name !== chatSectionsEnum.ADMIN"
    >
      <span [class.text-overflow]="chatSectionSelected.name === chatSectionsEnum.ADMIN" [title]="theme.title">
        {{ theme.title }}</span
      >
    </div>
  </div>
  <div
    class="d-flex align-items-center theme-menu"
    [style.marginLeft]="scrollPosition + 'px'"
    [class.info__selected]="contactSelected.room_id === theme.room_id || themeSelected?.room_id === theme?.room_id"
  >
    <button
      class="icon-button cog-button"
      [class.disabled]="isOwner"
      type="button"
      (click)="$event.stopPropagation()"
      placement="left"
      [ngbPopover]="settingsContent"
      container="body"
    >
      <app-svg-icon [name]="'cog'" width="15" height="15"></app-svg-icon>
    </button>
    <div [class.hidden]="!theme.menuExpanded"></div>
    <span
      [style.visibility]="totalUnreadCounter ? 'visible' : 'hidden'"
      [class.chat-number__10]="totalUnreadCounter === '10+'"
      class="chat-number theme-chat__number"
      >{{ totalUnreadCounter }}</span
    >
  </div>
</div>

<app-modal-window [isShowed]="isReallyRemoving" (closeEvent)="isReallyRemoving = false" centered="true">
  <p class="mb-4">Вы действительно хотите удалить тему?</p>
  <p class="mb-5 text-overflow">Восстановить удаленные данные будет невозможно!</p>
  <div class="d-flex justify-content-between">
    <button class="btn btn-dc-outline" (click)="isReallyRemoving = false">Нет, отменить</button>
    <button class="btn btn-dc-primary" (click)="onRemoveTheme()">Да, применить</button>
  </div>
</app-modal-window>

<app-modal-window [isShowed]="isShowRenameModal" (closeEvent)="isShowRenameModal = false" centered="true">
  <app-chat-group-theme-name-form
    *ngIf="isShowRenameModal"
    [isShowed]="isShowRenameModal"
    [name]="theme.title"
    title="Переименовать тему"
    placeholder="Введите название темы"
    (cancelEdit)="closeModalName()"
    (saveName)="renameTheme($event)"
  ></app-chat-group-theme-name-form>
</app-modal-window>

<ng-template #settingsContent>
  <button class="settings-buttons" (click)="$event.stopPropagation(); openRenameThemeModal()">
    <app-svg-icon [name]="'edit_list'" width="15" height="15"></app-svg-icon>
    Переименовать тему
  </button>
  <button class="settings-buttons" (click)="$event.stopPropagation(); onThemeEdit()">
    <app-svg-icon [name]="'users'" width="15" height="15"></app-svg-icon>
    Редактировать тему
  </button>
  <button class="settings-buttons" (click)="$event.stopPropagation(); onRemoveThemeAsk()">
    <app-svg-icon [name]="'delete'" width="15" height="15"></app-svg-icon>
    Удалить тему
  </button>
</ng-template>
