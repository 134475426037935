<div class="tech">
  <div class="d-flex align-items-center tech-left">
    <button [disabled]="disabled" class="icon-button chat-expansion__caret" (click)="onToggleExpanded()">
      <app-svg-icon [name]="isExpanded ? 'caret_up' : 'caret_down'" width="12" height="12"></app-svg-icon>
    </button>
    <span class="tech__title">{{ title }}</span>
  </div>

  <div class="d-flex align-items-center tech-right">
    <button class="icon-button">
      <app-svg-icon [name]="'question-circle-color'" width="16" height="16"></app-svg-icon>
    </button>
    <button
      class="icon-button"
      (click)="openEditor()"
      [ngStyle]="{ 'padding-bottom': myTso ? '8px' : '0' }"
      [ngClass]="{ tso: buttonText }"
    >
      <app-svg-icon *ngIf="myTso" [name]="'check'" width="8" height="8" class="choosen-icon"></app-svg-icon>
      <app-svg-icon *ngIf="iconName" [name]="iconName" width="16" height="16"></app-svg-icon>
      {{ buttonText }}
    </button>

    <span
      class="chat-number"
      [style.visibility]="totalUnreadCounter ? 'visible' : 'hidden'"
      [class.chat-number__10]="totalUnreadCounter === '10+'"
      >{{ totalUnreadCounter }}</span
    >
  </div>
</div>
