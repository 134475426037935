<div class="text-center" [formGroup]="form">
  <div class="d-flex justify-content-between mb-4 range-wrapper">
    <div class="mr-1 range-number-label" *ngIf="label">{{ label }}</div>
    <div class="range-wrapper">
      <input
        type="text"
        name="from"
        class="kt-input kt-small2-input"
        placeholder="От"
        formControlName="from"
        onlyInteger
        ngDefaultControl
        appBlockCopyPaste
      />
      <input
        type="text"
        name="to"
        onlyInteger
        class="kt-input kt-small2-input"
        placeholder="До"
        formControlName="to"
        ngDefaultControl
        appBlockCopyPaste
      />
    </div>
    <div class="input-wrapper__errors">
      <div class="input-wrapper__error" *ngIf="isInvalidRange || isFormInvalid">Некорректный диапазон</div>
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <button
      class="btn btn-dc-primary"
      ngbAutofocus
      [disabled]="!isActiveButton || isInvalidRange || isFormInvalid"
      (click)="apply()"
    >
      Фильтровать
    </button>
  </div>
</div>
