import { Component, OnInit } from '@angular/core';
import { DestroyService } from '@app/services/destroy.service';
import { User } from '@app/shared/models/user.model';
import { AuthService } from '@app/shared/services/auth.service';
import { takeUntil } from 'rxjs/operators';
import { ChatService } from '../services/chat.service';
import { ChatRoom } from '../models/chat.model';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { PersonalDataService } from '@app/shared/services/personal-data.service';
import { TechTypeEnum } from '../constants/chat-sections.constants';

type TechSectionType = 'isMyTechExpanded' | 'isMyUsersExpanded' | 'isAllUsersExpanded';

@Component({
  selector: 'app-tech-chat',
  templateUrl: './tech-chat.component.html',
  styleUrls: ['./tech-chat.component.scss'],
})
export class TechChatComponent implements OnInit {
  user: User;
  rolesEnum = RolesEnum;
  myTso: User | null = null;
  techTypeEnum = TechTypeEnum;

  isMyTechExpanded = true;
  isMyUsersExpanded = false;
  isAllUsersExpanded = false;

  isShowedTsoEditor = false;

  availableTso: User[] = [];
  selectedTso: User | null = null;

  isDutyTSO = false;
  isTSO = false;

  constructor(
    private authService: AuthService,
    private destroy: DestroyService,
    private chatService: ChatService,
    private personalDataService: PersonalDataService
  ) {}

  get totalUnreadCounterMyTech() {
    return '10+';
  }

  get totalUnreadCounterMyUser() {
    return '10+';
  }

  get totalUnreadCounterAllUser() {
    return '10+';
  }

  ngOnInit(): void {
    this.authService.userStream.pipe(takeUntil(this.destroy)).subscribe((user) => {
      this.isTSO = user.flags.is_tso;
      this.isDutyTSO = user.flags.is_duty_tso;
      this.user = user;
    });

    //Пока получаю данные по гет запросу, но возможно нужен будет сокет. Делать в рамках или задачи 1734
    // или при редизайне сметны ОТП
    this.personalDataService
      .getSelfTso()
      .pipe(takeUntil(this.destroy))
      .subscribe((user) => {
        this.myTso = user;
      });

    this.personalDataService
      .getReadyTso()
      .pipe(takeUntil(this.destroy))
      .subscribe((data) => {
        this.availableTso = data.filter((user) => user.id !== this.user.id);
      });
  }

  onToggleExpanded(property: TechSectionType): void {
    this[property] = !this[property];
  }

  openTsoEdit() {
    this.isShowedTsoEditor = true;
  }

  openActivatedTso() {
    return;
  }

  openDutyEdit() {
    return;
  }

  closeTsoEdit() {
    this.isShowedTsoEditor = false;
  }

  changeTso() {
    const id = this.selectedTso.id ? this.selectedTso.id : null;
    this.personalDataService.bindTsoForUser(id).subscribe((user) => {
      this.myTso = user.tso;
    });

    this.closeTsoEdit();
  }

  changeSelection(user: User) {
    this.selectedTso = user;
  }
}
