import { Pipe, PipeTransform } from '@angular/core';
import { GroupSortingNames } from '@app/chat/constants/chat-sections.constants';
import { ChatGroupOrder, ChatRoom } from '@app/chat/models/chat.model';
import { deepCopy } from '@app/shared/utils';
import { AuthService } from '@app/shared/services/auth.service';

@Pipe({
  name: 'groupSort',
})
export class GroupSortPipe implements PipeTransform {
  userId;

  constructor(private authService: AuthService) {
    this.userId = +this.authService.user_id;
  }
  transform(groups: ChatRoom[], order: ChatGroupOrder): ChatRoom[] {
    if (order.name === GroupSortingNames.OWNER || order.parent === GroupSortingNames.OWNER) {
      const myGroups = this.sortByOwner(deepCopy(groups)).filter((group) => {
        return group.owner_id === this.userId;
      });

      const otherGroups = this.sortByOwner(deepCopy(groups)).filter((group) => {
        return group.owner_id !== this.userId;
      });

      if (order.parent) {
        if (order.name === GroupSortingNames.LAST_MESSAGE_DATE) {
          this.sortByLastMessage(myGroups);
          this.sortByLastMessage(otherGroups);
        }

        if (order.name === GroupSortingNames.CREATED_DATE) {
          this.sortByCreatedDate(myGroups);
          this.sortByCreatedDate(otherGroups);
        }

        if (order.name === GroupSortingNames.TITLE) {
          this.sortByTitle(myGroups);
          this.sortByTitle(otherGroups);
        }
      }

      return myGroups.concat(otherGroups);
    }

    if (order.name === GroupSortingNames.TITLE) {
      return this.sortByTitle(deepCopy(groups));
    }

    if (order.name === GroupSortingNames.LAST_MESSAGE_DATE) {
      // глубокое копирование вызывает баг сворачиванием групп при смене активной
      return this.sortByLastMessage(groups);
      // return this.sortByLastMessage(deepCopy(groups));
    }
    return groups;
  }

  sortByTitle(groups: ChatRoom[]) {
    return groups.sort((a, b) => {
      return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
    });
  }

  sortByLastMessage(groups: ChatRoom[]) {
    return groups.sort((a, b) => {
      if (b.last_message_creation_date_in_chat && a.last_message_creation_date_in_chat) {
        return +new Date(b.last_message_creation_date_in_chat) - +new Date(a.last_message_creation_date_in_chat);
      }

      if (b.last_message_creation_date_in_chat && !a.last_message_creation_date_in_chat) {
        return 1;
      }

      if (!b.last_message_creation_date_in_chat && a.last_message_creation_date_in_chat) {
        return -1;
      }

      return 0;
    });
  }

  sortByCreatedDate(groups: ChatRoom[]) {
    return groups.sort((a, b) => {
      return +new Date(a.created_at) - +new Date(b.created_at);
    });
  }

  sortByOwner(groups: ChatRoom[]) {
    return groups.sort((a, b) => {
      if (b.owner_id === this.userId && a.owner_id !== this.userId) {
        return 1;
      }

      if (b.owner_id !== this.userId && a.owner_id === this.userId) {
        return -1;
      }

      if (b.owner_id === this.userId && a.owner_id === this.userId) {
        return 0;
      }
    });
  }
}
