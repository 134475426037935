<div
  (mouseup)="onTabSelect()"
  class="chat__tab"
  [title]="getTabTitle()"
  [ngClass]="{ active: active }"
  [style.cursor]="isDragging ? 'grabbing' : active ? 'default' : 'pointer'"
>
  <div class="chat__tab-title">
    {{ getTabTitle() }}
  </div>

  <div *ngIf="!active && tab.counter" class="chat__tab-counter">
    {{ tab.counter }}
  </div>

  <div (click)="onTabRemove($event)" *ngIf="active" class="chat__tab-times" [ngClass]="{ dragging: isDragging }">
    <app-svg-icon [name]="'close'" width="20" height="20" class="close-icon"></app-svg-icon>
  </div>
</div>
