<div>
  <!-- Позже убрать -->
  <app-change-tso [user]="user"></app-change-tso>
</div>
<app-tech-chat-title
  title="Моя тех. поддержка"
  [myTso]="!!myTso"
  iconName="tools"
  [totalUnreadCounter]="totalUnreadCounterMyTech"
  (toggled)="onToggleExpanded('isMyTechExpanded')"
  (edit)="openTsoEdit()"
></app-tech-chat-title>

<div class="tech-content" *ngIf="isMyTechExpanded">
  <app-chat-groups [techType]="techTypeEnum.MY_TECH"></app-chat-groups>
  <app-chat-contacts [techType]="techTypeEnum.MY_TECH"></app-chat-contacts>
</div>

<div class="tech-border" *ngIf="user.type === rolesEnum.PARTNER"></div>

<app-tech-chat-title
  *ngIf="user.type === rolesEnum.PARTNER"
  title="Мои пользователи"
  [disabled]="!isTSO"
  iconName=""
  buttonText="ОТП"
  [totalUnreadCounter]="totalUnreadCounterMyUser"
  (toggled)="onToggleExpanded('isMyUsersExpanded')"
  (edit)="openActivatedTso()"
></app-tech-chat-title>

<div class="tech-content" *ngIf="isMyUsersExpanded">
  <app-chat-groups [techType]="techTypeEnum.MY_USERS"></app-chat-groups>
  <app-chat-contacts [techType]="techTypeEnum.MY_USERS"></app-chat-contacts>
</div>

<div class="tech-border" *ngIf="user.type === rolesEnum.PARTNER"></div>

<app-tech-chat-title
  *ngIf="user.type === rolesEnum.PARTNER"
  title="Все пользователи"
  [disabled]="!isDutyTSO"
  iconName="calendar-check-dark"
  [totalUnreadCounter]="totalUnreadCounterAllUser"
  (toggled)="onToggleExpanded('isAllUsersExpanded')"
  (edit)="openDutyEdit()"
></app-tech-chat-title>

<div class="tech-content" *ngIf="isAllUsersExpanded">
  <app-chat-groups [techType]="techTypeEnum.ALL_USERS"></app-chat-groups>
  <app-chat-contacts [techType]="techTypeEnum.ALL_USERS"></app-chat-contacts>
</div>

<app-modal-window [isShowed]="isShowedTsoEditor" (closeEvent)="closeTsoEdit()" customClass="change-tso-modal">
  <app-select-user-list
    [employees]="availableTso"
    [user]="myTso"
    *ngIf="isShowedTsoEditor"
    (onChangeEvent)="changeSelection($event)"
  ></app-select-user-list>
  <div class="d-flex justify-content-center">
    <button class="btn btn-dc-primary" (click)="changeTso()">Сохранить</button>
  </div>
</app-modal-window>
